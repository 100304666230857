<template>
  <div id="app">
    <keep-alive>
      <router-view class="router" v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view class="router" v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
import util from './util'

export default {
  name: 'App',
  created() {
    this.$router.onReady(() => {
      if(this.$route.query.team_id){
        sessionStorage.setItem('team_id', this.$route.query.team_id)
      }
      if(this.$route.query.lang){
        let feloLang = this.$route.query.lang
        localStorage.setItem('i18n', this.langFilter(feloLang.substring(0,2)))
        document.querySelector('html').setAttribute('lang', feloLang)
      }else{
        let browserLang = navigator.language.substring(0,2)
        localStorage.setItem('i18n',this.langFilter(browserLang))
        document.querySelector('html').setAttribute('lang', navigator.language)
      }
      let lang = localStorage.getItem('i18n')
      this.$i18n.locale = lang
      util.setTeam()
    });
  },
  methods: {
    langFilter(lang){
      const obj = {
        'zh': 'zh-CN',
        'en': 'en-US',
        'ja': 'jap',
      }
      return obj[lang] || 'en-US'
    },
  }
}
</script>
<style lang="stylus">
@font-face {
    font-weight: normal;
    font-family: 'regular';
    font-style: normal;
    src: url('~@/assets/fonts/regular.woff2') format('woff2'),
        url('~@/assets/fonts/regular.woff') format('woff'),
        url('~@/assets/fonts/regular.ttf') format('truetype');
}

@font-face {
    font-weight: normal;
    font-family: 'medium';
    font-style: normal;
    src: url('~@/assets/fonts/medium.woff2') format('woff2'),
        url('~@/assets/fonts/medium.woff') format('woff'),
        url('~@/assets/fonts/medium.ttf') format('truetype');
}

@font-face {
    font-weight: normal;
    font-family: 'opensans-regular';
    font-style: normal;
    src: url('~@/assets/fonts/opensans-regular.woff2') format('woff2'),
        url('~@/assets/fonts/opensans-regular.woff') format('woff'),
        url('~@/assets/fonts/opensans-regular.ttf') format('truetype');
}

@font-face {
    font-weight: normal;
    font-family: 'opensans-medium';
    font-style: normal;
    src: url('~@/assets/fonts/opensans-medium.woff2') format('woff2'),
        url('~@/assets/fonts/opensans-medium.woff') format('woff'),
        url('~@/assets/fonts/opensans-medium.ttf') format('truetype');
}
*{
  box-sizing: border-box;
}
#app {
  font-family: 'regular', 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
        'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
        'Segoe UI Symbol', 'Noto Color Emoji';
  text-align: center;
  color: #2c3e50;
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  /* min-width: 1250px; */
}
div[id^="mobile-"]{
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  background: #f9fafc;
}
html,body{
  margin: 0;
  padding: 0;
}
.container{
  height: 100vh;
}
.scroll-wrap{
  height: 100vh;
  /* overflow-y: hidden; */
  margin-top: 60px;
}
.scroll-container{
  height: calc(100vh - 60px);
  min-width: 1300px;
  overflow-y: auto;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

#header-left i{
  cursor: pointer;
}
#header-left img{
  width: 30px;
  height: 30px;
  vertical-align: middle;
  margin-left: 10px;
  margin-right: 10px;
}

ul,li{
  list-style: none;
  padding: 0;
}
.van-dialog__confirm{
  color: #409eff !important;
}
.el-upload-list__item-name{
  white-space: normal !important;
  word-break: break-all;
}
</style>
