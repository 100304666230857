<template>
  <div class="answer-container">
    <div class="answer-info">
      <div class="info-left">
        {{$t('lang.collectDetail_WriteUser')}}：<template v-if="name==''">{{$t('lang.anonymousUser')}}</template><template v-else>{{name}}</template>
        <span>({{ internationFormat(time) }})</span>
      </div>
      <div class="info-right">
        <!-- <i class="el-icon-delete-solid"></i> -->
      </div>
    </div>
    <div class="answer-detail">
      <p class="title">{{formTitle}}</p>
      <div class="answer-list" v-for="item in writeDetail" :key="item.id">
        <p class="question">{{item.order}}.{{item.title}}</p>

        <p class="answer" v-if="item.type == 'text' || item.type == 'textarea'">{{item.value.answer}}</p>
        <p class="answer" v-if="item.type == 'radio'">{{item.value.answer | answerFilter(item)}}</p>
        <div class="answer" v-if="item.type == 'select'">
          <p v-for="check in item.value.answer" :key="check">{{check | answerFilter(item)}}</p>
        </div>
        <p v-if="item.type == 'score'">
          <el-rate v-model="item.value.answer" disabled show-score></el-rate>
        </p>
        <div class="answer" v-if="item.type == 'input'">
          <p>
              <a v-if="isOldFile(item.value.answer)" :href="`/${item.value.answer}`" :download="item.value.answer">{{item.value.answer}}</a>
              <a v-else :href="item.value.answer" :download="formatFileName(item.value.answer)">{{formatFileName(item.value.answer)}}</a>
            </p>
        </div>
        <div class="answer" v-if="item.type == 'inputs'">
          <p v-for="file in item.value.answer" :key="file">
            <a v-if="isOldFile(file)" :href="`/${file}`" :download="file">{{file}}</a>
            <a v-else :href="file" :download="formatFileName(file)">{{formatFileName(file)}}</a>
          </p>
        </div>
        <p class="images" v-if="item.type == 'images'">
          <el-image 
            style="width: 100px; height: 100px; margin-right:20px"
            v-for="pic in item.value.answer" :key="pic"
            fit="contain"
            :src="isOldFile(pic) ? `${baseUrl}${pic}` : `${pic}`" 
            :preview-src-list="isOldFile(pic) ? [`${baseUrl}${pic}`] : [`${pic}`]">
          </el-image>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api/api'

export default {
  name: 'SingleAnswer',
  props: {
    id: {
      type: String,
      default: ''
    },
    formTitle: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    time: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      userName: '',
      updateAt: '',
      writeDetail: [],
      baseUrl: '',
    }
  },
  watch: {
    id(val){
      this.queryWriteDetail(val)
    }
  },
  filters: {
    answerFilter(value, item){
      let answer = ''
      item.context.forEach(radio => {
        if(radio.label == value){
          if(radio.type == 'other'){
            answer = `${radio.title} ( ${item.value.other_content} )`
          }else{
            answer = radio.title
          }
        }
      })
      return answer
    }
  },
  created(){
    this.baseUrl = this.$store.state.picBaseUrl
    this.queryWriteDetail(this.id)
  },
  methods: {
    queryWriteDetail(id){
      api.queryFormDetail({
        limit: 9999,
        page: 1,
        order: 'id',
        id,
      }).then(res => {
        this.writeDetail = res.data
      })
    }
  }
}
</script>

<style lang="stylus">
  .answer-container{
    border-top: 1px solid #e7e9eb;
    min-height: 100%;
  }
  .answer-info{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    font-size: 14px;
  }
  .answer-info .info-left{
    display: flex;
    align-items: center;
  }
  .answer-info .info-left img{
    width: 25px;
    height: 25px;
    border-radius: 50%;
    margin-right: 5px;
  }
  .answer-info .info-left span{
    margin-left: 20px;
  }
  .answer-detail .title{
    font-size: 20px;
  }
  .answer-list{
    text-align: left;
    font-size: 14px;
    padding-bottom: 15px;
  }
  .answer-list .question{
    font-weight: bold;
  }
  .answer-list .answer{
    padding-left: 1em;
    line-height: 1.25em;
    word-break: break-all;
  }
  .answer img{
    height: 80px;
    margin-right: 20px;
  }
</style>