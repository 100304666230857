import axios from 'axios';
import store from '@/store'
import util from '@/util'
import { Message, Loading } from 'element-ui'
import { Toast } from 'vant'
//设置请求超时
axios.defaults.timeout =  600000;
// axios.defaults.baseURL = 'http://54.199.17.49:20191'

// 影响上传，暂时去掉
// axios.interceptors.request.use(config => {
//   config.headers['Authorization'] = `Bearer ${localStorage.getItem("form_actoken")}`
//   return config
// }, err => {
//   return Promise.reject(err)
// })
const next = function(){
  location.reload()
}
axios.interceptors.response.use(res => {
  return res;
}, err => {
  if(err.response.data.exp == 'token expired'){
    if (['WriteForm', 'TemplateMgmt', 'WriteEmbed'].includes(store.state.toPage.name)){
      util.login(next, true)
    }else{
      util.login(next)
    }
  }
  return Promise.reject(err);
});

const isMobile = store.state.isMobile
export default {
  async gopost(url,data ={}, noLoading, error){
    // await util.judgeTime()
    return new Promise((resolve,reject) =>{
      let loading = {}
      if(!noLoading){
        loading = Loading.service({
          lock: true,
          spinner: 'el-icon-loading',
          background: 'rgba(255, 255, 255, 0.4)'
        })
      }
      axios.post(url, data, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("form_actoken")}`
        }
      }).then(response =>{
        if(!noLoading){
          loading.close()
        }
        if (response.data.code!=200){
          if(error){
            reject(response.data)
          }else{
            if(isMobile){
              Toast.fail(response.data.msg)
            }else{
              // Message.error(response.data.msg)
            }
          }
        }else{
          resolve(response.data)
        }
      },err =>{
        if(!noLoading){
          loading.close()
        }
        if(err.response.data.exp == 'token expired') return
        if(isMobile){
          Toast.fail('Your request error, please try again!')
        }else{
          if (err.response.data.msg){
            Message.error(err.response.data.msg)
          }else {
            Message.error("Your request error, please try again!")
          }
        }
      })
    })
  },
  goget(url,params ={}){
    return new Promise((resolve,reject) =>{
      let loading = Loading.service({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.4)'
      })
      axios.get(url, {
        params,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem("form_actoken")}`
        }
      }).then(response =>{
        loading.close()
        resolve(response.data)
      }).catch(err =>{
        loading.close()
        if(err.response.data.exp == 'token expired') return
        Message.error(err)
        reject(err)
      })
    })
  },
  async goUpload(url, data={}){
    // await util.judgeTime()
    return new Promise((resolve,reject) =>{
      let loading = Loading.service({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.4)'
      })
      axios.post(url,data,{
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem("form_actoken")}`
        },
      }).then(response =>{
        loading.close()
        if (response.data.code!=200){
          if(url.indexOf('form_write_update') > -1 ){
            resolve(response.data)
          }else{
            if(isMobile){
              Toast.fail(response.data.msg)
            }else{
              Message.error(response.data.msg)
            }
          }
        }else{
          resolve(response.data)
        }
      },err =>{
        loading.close()
        if(err.response.data.exp == 'token expired') return
        if(isMobile){
          Toast.fail('Your request error, please try again!')
        }else{
          Message.error("Your request error, please try again!")
        }
        reject(err)
      })
    })
  },
  goput(url,params,config ={}){
    return new Promise((resolve,reject) =>{
      let loading = Loading.service({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 0.4)'
      })
      axios.put(url,params,config).then(response =>{
        loading.close()
        if (response.status!=200){
          if(isMobile){
            Toast.fail(response.data.msg)
          }else{
            Message.error(response.data.msg)
          }
        }else{
          resolve(response.data)
        }
      }).catch(err =>{
        loading.close()
        if(err.response.data.exp == 'token expired') return
        Message.error(err)
        reject(err)
      })
    })
  },
}
