<template>
	<div class="cont">
		<el-container class="container">
			<!-- 左侧菜单 start -->
		  <el-aside class="side">
				<div class="title">
					<img class="imgdoc" src="../assets/images/form-icon.png"/>
        	{{$t('lang.Header_title')}}
				</div>
			  <div class="menu">
				  <div @click="chooseFun(1)" class="fenlei" :class="choose==1?'bgcolor':''"><i class="el-icon-s-claim"></i>{{$t('lang.Index_createdForms')}}</div>
				  <div @click="chooseFun(2)" class="fenlei" :class="choose==2?'bgcolor':''"><i class="el-icon-s-order"></i>{{$t('lang.Index_writeForms')}}</div>
			  </div>
				<div class="bottom">
					<el-select v-model="nowTeam" size="mini">
						<el-option v-for="item in userInfo.teams" :key="item.id" :label="item.name" :value="item.id"></el-option>
					</el-select>
				</div>
		  </el-aside>
			<!-- 左侧菜单 end -->
			<el-main class="main-content">
				<div class="content-wrap">
					<!-- 热门推荐 start -->
					<div class="content-block">
						<div class="block-title">
							<div>{{$t('lang.Index_quickCreate')}}</div>
							<el-button size="mini" type="primary" icon="el-icon-circle-plus" @click="openNewFormDialog">{{$t('lang.createNewForm')}}</el-button>
							<!-- <div class="right" @click="createNewForm">更多模板</div> -->
						</div>
						<div class="recommend-list">
							<div class="recommend-item" v-for="item in tempList" :key="item.id" @click="useTemp(item)">
								<i class="icon el-icon-document-add"></i>
								<p>{{item.name}}</p>
							</div>
							<div class="recommend-item" @click="createNewForm">
								<i class="el-icon-right"></i>
								<p>{{$t('lang.goTempMkt')}}</p>
							</div>
						</div>
					</div>
					<!-- 热门推荐 end -->
					<!-- 表单列表 start -->
					<div class="content-block">
						<!-- 我创建的 start -->
						<div v-if="choose == 1">
							<div class="block-title">
								<el-tabs v-model="activeName" @tab-click="formTabClick">
									<el-tab-pane :label="$t('lang.allForms')" name=""></el-tab-pane>
									<el-tab-pane :label="$t('lang.pushedForms')" name="is_push=true AND sta=1"></el-tab-pane>
									<el-tab-pane :label="$t('lang.finishedForms')" name="sta=2"></el-tab-pane>
									<el-tab-pane :label="$t('lang.Index_draft')" name="is_push=false"></el-tab-pane>
								</el-tabs>
								<div class="table-filters">
									<el-button size="mini" :icon="formListStar? 'el-icon-star-on':'el-icon-star-off'" @click="formListStar = !formListStar">{{$t('lang.Index_star')}}</el-button>
									<el-input
										class="search-input"
										size="mini"
										:placeholder="$t('lang.SearchForm')"
										v-model="search"
										@input="queryFormList"
										clearable>
										<i slot="prefix" class="el-input__icon el-icon-search"></i>
									</el-input>
								</div>
							</div>
							<div v-if="myFormList.length > 0">
								<el-table
									row-class-name="table-row"
									:data="myFormList">
									<el-table-column
										:label="$t('lang.formTitle')">
										<template slot-scope="scope">
											<span class="form-name">{{scope.row.name}}</span>
											<el-tag v-if="scope.row.sta == 2" size="mini" type="danger">{{$t('lang.finishedForms')}}</el-tag>
											<el-tag v-else-if="scope.row.is_push" size="mini">{{$t('lang.collecting')}}</el-tag>
											<el-tag v-else size="mini" type="info">{{$t('lang.Index_draft')}}</el-tag>
										</template>
									</el-table-column>
									<el-table-column
										align="center"
										:label="$t('lang.answerNums')">
										<template slot-scope="scope">
											<span class="desc-span" v-if="scope.row.is_push">{{scope.row.count}}{{$t('lang.nAnswer')}}</span>
										</template>
									</el-table-column>
									<el-table-column
										align="center"
										:label="$t('lang.updateTime')">
										<template slot-scope="scope">
											<!-- <span class="desc-span" v-if="!scope.row.is_push && scope.row.start_sta == 2">
												{{ scope.row.start_time | internationFormat }}{{$t('lang.Index_SubmitRun')}}
											</span>
											<span class="desc-span" v-else-if="scope.row.is_push && scope.row.off_sta == 1">
												{{ scope.row.off_time | internationFormat }}{{$t('lang.Index_SubmitStop')}}
											</span>
											<span class="desc-span" v-else>{{$t('lang.updateAt')}}{{ scope.row.UpdatedAt | internationFormat }}</span> -->
											<span class="desc-span">{{ internationFormat(scope.row.UpdatedAt) }}</span>
										</template>
									</el-table-column>
									<el-table-column
										align="right"
										:label="$t('lang.opearte')">
										<template slot-scope="scope">
											<!-- 按钮 -->
											<el-button v-if="scope.row.is_push" round class="hover-dom" type="primary" size="mini" @click="goDetail(scope.row)">{{$t('lang.collectDetail_SumSuccess')}}</el-button>
											<el-button v-if="!scope.row.is_push" round class="hover-dom" size="mini" @click="goDetail(scope.row)">{{$t('lang.Index_ContinueEditing')}}</el-button>
											
											<!-- 分享 -->
											<i v-if="scope.row.is_push && scope.row.sta != 2" class="el-icon-top-right hover-dom table-icon" @click="invite(scope.row)"></i>
											
											<!-- 星标 -->
											<i v-if="scope.row.star == 1" class="el-icon-star-on table-icon" @click="updateStar(scope.row, 2)"></i>
											<i v-else class="el-icon-star-off hover-dom table-icon" @click="updateStar(scope.row, 1)"></i>
											
											<!-- 更多 -->
											<el-dropdown class="more-icon" trigger="click"
												:hide-on-click="true"
												placement="bottom"
												@command="formListCommand">
												<i class="el-icon-more" ></i>
												<el-dropdown-menu slot="dropdown">
													<el-dropdown-item :command="{type: 'stopCollect',item: scope.row}" v-if="scope.row.is_push && scope.row.sta == 1">{{$t('lang.Index_Stop')}}</el-dropdown-item>
													<el-dropdown-item :command="{type: 'goCollect',item: scope.row}" v-if="scope.row.sta == 2">{{$t('lang.Index_continue')}}</el-dropdown-item>
													<el-dropdown-item :command="{type: 'write',item: scope.row}" v-if="scope.row.is_push && scope.row.sta == 1">{{$t('lang.Index_Fill')}}</el-dropdown-item>
													<el-dropdown-item :command="{type: 'copy',item: scope.row}">{{$t('lang.Index_Copy')}}</el-dropdown-item>
													<el-dropdown-item :command="{type: 'setting',item: scope.row}">{{$t('lang.Index_Setting')}}</el-dropdown-item>
													<el-dropdown-item :command="{type: 'delete',item: scope.row}">{{$t('lang.Index_Del')}}</el-dropdown-item>
												</el-dropdown-menu>
											</el-dropdown>
										</template>
									</el-table-column>
								</el-table>
								<el-pagination
									class="pagination"
									layout="prev, pager, next"
									:current-page.sync="formCurrentPage"
									:total="formTotal"
									@current-change="queryFormList(formCurrentPage)">
								</el-pagination>
							</div>
							<el-empty v-else :description="$t('lang.NoData')"></el-empty>
						</div>
						<!-- 我创建的 end -->
						<!-- 我填写的 start -->
						<div v-if="choose == 2">
							<div v-if="myWriteFormList.length > 0">
								<el-table
									row-class-name="table-row"
									:data="myWriteFormList">
									<el-table-column
										:label="$t('lang.formTitle')">
										<template slot-scope="scope">
											<span class="form-name">{{scope.row.form_main_name}}</span>
										</template>
									</el-table-column>
									<el-table-column
										align="center"
										:label="$t('lang.answerTime')">
										<template slot-scope="scope">
											<span class="desc-span">{{ internationFormat(scope.row.UpdatedAt) }}</span>
										</template>
									</el-table-column>
									<el-table-column
										align="right"
										:label="$t('lang.opearte')">
										<template slot-scope="scope">
											<el-dropdown class="more-icon" trigger="click"
												:hide-on-click="true"
												placement="bottom"
												@command="deleteWriteForm">

												<span class="item-type">
													<i class="el-icon-more" ></i>
												</span>
												<el-dropdown-menu slot="dropdown">
													<el-dropdown-item
														:command="scope.row">
														{{$t('lang.Index_Del')}}
													</el-dropdown-item>
												</el-dropdown-menu>
											</el-dropdown>
										</template>
									</el-table-column>
								</el-table>
								<el-pagination
									class="pagination"
									layout="prev, pager, next"
									:current-page.sync="writeFormCurrentPage"
									:total="writeFormTotal"
									@current-change="queryWriteList(writeFormCurrentPage)">
								</el-pagination>
							</div>
							<el-empty v-else :description="$t('lang.NoData')"></el-empty>
						</div>
						<!-- 我填写的 end -->
					</div>
					<!-- 表单列表 end -->
				</div>
			</el-main>
		</el-container>

		<el-dialog
      :visible.sync="newFormDialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeNewFormDialog"
      width="450px" class="create-dialog">
      <div class="title">{{$t('lang.formCreateform')}}</div>
      <div class="input-div">
        <el-input class="title-input" v-model="newFormTitle" :placeholder="$t('lang.formWriteTitle')"></el-input>
        <el-button type="primary" @click="createForm">{{$t('lang.formCreatedSub')}}</el-button>
      </div>
			<el-divider><span class="line">{{$t('lang.formMoreform')}}</span></el-divider>
			<div class="more-action">
				<span class="item">
					<i class="el-icon-document-copy"  @click="openCopyFormDialog"></i>
					<span>{{$t('lang.formCopy')}}</span>
				</span>
			</div>
    </el-dialog>
		<el-dialog
			:title="$t('lang.formIsOne')"
      :visible.sync="copyFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeCopyFormDialog"
      width="600px" class="copy-form-dialog">
			<ul class="copy-form-list" 
				v-if="copyFormList.length > 0"
				v-infinite-scroll="queryCopyList" 
				:infinite-scroll-disabled="infiniteScroll"
				style="overflow:auto">
				<li v-for="item in copyFormList" :key="item.id" class="copy-list-item">
					<span>{{ item.name }}</span>
					<div>
						<span>{{ internationFormat(item.UpdatedAt) }}{{$t('lang.Index_Svae')}}</span>
						<span class="copy" @click="copyFormToEdit(item)">{{$t('lang.formCopyFe')}}</span>
					</div>
				</li>
				<li v-if="infiniteScroll">
					<el-divider><span class="line">{{$t('lang.formNodeDtta')}}</span></el-divider>
				</li>
			</ul>
			<el-empty v-else :description="$t('lang.NoData')"></el-empty>
			
    </el-dialog>
		<!-- 设置 -->
		<el-dialog 
			class="setting-dialog"
      :title="$t('lang.Header_set')"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="settingVisible"
      @close="settingDialogClose">
      <Setting 
        ref="settings"
				v-if="settingVisible"
        :formSettings="formSettings"
        :editFlag="true"
        @confirm="settingConfirm" />
      <div slot="footer">
        <el-button @click="settingDialogCancel">{{$t('lang.ButtonCall')}}</el-button>
        <el-button type="primary" @click="settingDialogConfirm">{{$t('lang.ButtonSub')}}</el-button>
      </div>
    </el-dialog>

		<ShareDialog :share="shareVisible" :shareUrl="nowItem.shareUrl" :index="true" @close="ShareDialogClose"/>
	</div>
</template>

<script>
import api from '@/api/api'
import store from '@/store'
import Header from '@/components/Header'
import Setting from '@/components/Setting'
import SearchBar from '@/components/SearchBar'
import ShareDialog from './pc/components/ShareDialog'
import { Message } from 'element-ui'
const moment = require('moment')

export default {
  name: 'Index',
	components: {
		Header,
		Setting,
		SearchBar,
		ShareDialog,
	},
  data(){
	  return {
			nowTeam: '',
      userInfo: {},
		  choose:1,
		  checkList:[],
		  off:true,
		  top:110,
		  left:110,
			formListStar: false,
			formListSoso: '',
			myFormList: [],
			formTotal: 0,
			formCurrentPage: 1,
			myWriteFormList: [],
			writeFormTotal: 0,
			writeFormCurrentPage: 1,
			tempList: [],
			newFormDialogVisible: false,
      newFormTitle: '',
			copyFormVisible: false,
			copyFormList: [],
			copyListPage: 0,
			infiniteScroll: false,
			settingVisible: false,
			shareVisible: false,
			formSettings: {},
			nowItem:{},
      creat:this.$t('lang.Index_meCreated'),
      write:this.$t('lang.Index_meWrite'),
			activeName: '',
			search: '',
	  }
  },
	watch:{
		choose(value){
			if(value == 1){
				this.queryFormList(1)
			}else if(value == 2){
				this.queryWriteList(1)
			}
		},
		formListStar(){
			this.refreshFormList()
		},
		nowTeam(team){
			localStorage.setItem('team', team)
			localStorage.setItem('form_actoken', this.userInfo.tokens[team])
			this.choose = 1
			this.refreshFormList()
		},
		'$i18n.locale'(){
      this.createShareUrl()
    }
	},
	activated(){
		// if(this.userId != localStorage.userId){
		// 	this.userId = localStorage.userId
		// 	this.choose = 1
		// 	this.refreshFormList()
		// }

		if( store.state.refreshFlag == 'NewForm'){
			this.choose = 1
			this.refreshFormList()
		}else if( store.state.refreshFlag == 'WriteForm'){
			this.choose = 2
			this.queryWriteList(1)
		}
  },
	created(){
		this.userInfo = JSON.parse(localStorage.getItem('login_info'))
		this.nowTeam = +localStorage.getItem('team')
		this.queryTempList()
		localStorage.removeItem('createFlag')
	},
  methods:{
		// sidebarChooseFilter(type){
    //   const obj = {
		// 		1: this.$t('lang.Index_meCreated'),
		// 		2: this.$t('lang.Index_meWrite'),
		// 	}
		// 	return obj[type]
		// },
		chooseFun(type){
			this.choose = type
		},
    formTabClick(type){
			this.formListSoso = type.name
			this.queryFormList(1)
		},
		refreshFormList(){
			this.queryFormList(1)
			this.formCurrentPage = 1
		},
		createShareUrl(){
			this.myFormList.forEach(item => {
				if(item.is_push){
					item.shareUrl = `${location.origin}/writeForm?formId=${item.id}`
				}
			})
		},
		queryTempList(){
			let soso = 'star=1 AND temp_lang='
      switch(this.$i18n.locale){
        case 'zh-CN':
          soso = `${soso}3`
          break
        case 'en-US':
          soso = `${soso}2`
          break
        case 'jap':
          soso = `${soso}1`
          break
      }
			api.queryTemp({
				limit: 10,
				page: 1,
				order: 'updated_at desc',
				soso,
			}).then(res => {
				this.tempList = res.data
			})
		},
		queryFormList(page){
			api.queryForm({
				limit: 10,
				page,
				order: 'updated_at desc',
				star: this.formListStar ? 1 : '',
				name: this.search,
				soso: this.formListSoso,
			}).then(res => {
				this.myFormList = res.data.list
				this.formTotal = res.count
				this.createShareUrl()
				store.state.refreshFlag == ''
			})
		},
		queryCopyList(){
			if(this.copyListPage == 0){
				this.copyFormList = []
			}
			this.copyListPage++
			api.queryForm({
				limit: 10,
				page: this.copyListPage,
				order: 'updated_at desc',
			}).then(res => {
				this.infiniteScroll = res.data.list.length < 10
				this.copyFormList = this.copyFormList.concat(res.data.list)
			})
		},
		queryWriteList(page){
			api.queryWriteForm({
				limit: 10,
				page,
				order: 'id desc',
			}).then(res => {
				this.myWriteFormList = res.data
				this.writeFormTotal = res.count
				store.state.refreshFlag == ''
			})
		},
		// createForm(){
		// 	this.$router.push({
		// 		path: '/formTemplate',
		// 	})
		// },
		goDetail(item){
			if(item.is_push){
				this.$router.push({
					path: '/collectDetail',
					query: {
						formId: item.id,
					}
				})
			}else{
				this.goEditForm(item.id)
			}
		},
		goEditForm(formId){
			this.$router.push({
				path: '/newForm',
				query: {
					formId,
					editFlag: true,
				}
			})
		},
		goPreview(item){
			this.$router.push({
				path: '/writeForm',
				query: {
					formId: item.form_main_db_id,
					writeId: item.id,
					submitDate: this.internationFormat(item.UpdatedAt),
					submit: item.sta == 2 ? 'submit':'',
				}
			})
		},
		updateStar(item, star){
			api.updateForm({
        id: item.id,
				star,
      }).then(res => {
        if(res.data.is_ok == 1){	
          item.star = star
        }  
      })
		},
		invite(item){
			console.log(item)
			this.nowItem = item
			this.shareVisible = true
		},
		ShareDialogClose(){
			this.shareVisible = false
		},
		formListCommand(command){
			switch (command.type){
				case 'stopCollect':
					this.updateCollectStatus(command.item, 2)
					break
				case 'goCollect':
					this.updateCollectStatus(command.item, 1)
					break
				case 'write':
					this.$router.push({
						path: '/writeForm',
						query: {
							formId: command.item.id
						}
					})
					break
				case 'copy':
					this.copyForm(command.item)
					break
				case 'setting':
					this.nowItem = command.item
					this.openSetting(command.item)
					break
				case 'delete': 
					this.deleteForm(command.item)
					break
				default:
					break
			}
		},
		updateCollectStatus(item, sta){
			api.updateForm({
        id: item.id,
				sta,
      }).then(res => {
				if(sta == 1){

					Message.success( this.$t('lang.Index_meCreated'))
				}else{
					Message.success(this.$t('lang.Index_CloseSta'))
				}
				this.refreshFormList()
      })
		},
		copyForm(item){
			api.copyForm({
				id: item.id
			}).then(res => {
				this.activeName = 'is_push=false'
				this.formListSoso = 'is_push=false'
				this.refreshFormList()
			})
		},
		openSetting(item){
			api.queryOneForm({
        id: item.id,
      }).then(res => {
        let data = res.data
        this.formSettings = {
					id: data.id,
					start_sta: data.start_sta,
          start_time: data.start_time,
          off_sta: data.off_sta,
          off_time: data.off_time,
          login_run: data.login_run,
          day_one: data.day_one,
          user_one: data.user_one,
          sub_count: data.sub_count,
          submitNum: data.sub_count == 0 ? 10 : data.sub_count,
          news_send: data.news_send,
					sta: data.sta,
					is_push: data.is_push,
					workflow_switch: data.workflow_switch,
					related_projects: data.related_projects,
					task_info: data.task_info,
        }
				this.settingVisible = true
      })
		},
		deleteForm(item){
			api.deleteForm({
				id: item.id,
			}).then(res => {
				Message.success(this.$t('lang.MsgDelSuccess'))
				this.queryFormList(this.formCurrentPage)
			})
		},
		deleteWriteForm(item){
			api.deleteFormWriter({
				id: item.id,
			}).then(res => {
        Message.success(this.$t('lang.MsgDelSuccess'))
				this.queryWriteList(this.writeFormCurrentPage)
			})
		},
		createNewForm(){
			this.$router.push('/template')
		},
		// 打开标题输入框
    openNewFormDialog(){
      this.newFormDialogVisible = true
    },
    // 关闭标题输入框
    closeNewFormDialog(){
			this.newFormDialogVisible = false
      this.newFormTitle = ''
    },
		openCopyFormDialog(){
			this.copyFormVisible = true
			this.copyListPage = 0
			this.queryCopyList()
		},
		closeCopyFormDialog(){
			this.copyFormVisible = false
		},
		// 复制已有表单
		copyFormToEdit(item){
			api.copyForm({
				id: item.id
			}).then(res => {
				this.closeCopyFormDialog()
				this.closeNewFormDialog()
				this.goEditForm(res.data)
			})
		},
		settingDialogClose(){
			this.settingVisible = false
		},
		settingDialogCancel(){
      this.settingDialogClose()
      this.$refs.settings.formatSettings()
    },
    settingDialogConfirm(){
      this.$refs.settings.confirm()
    },
		// 设置框确定
    settingConfirm(settings){
      // console.log(settings)
      api.updateForm({
        id: this.nowItem.id,
				start_sta: settings.start_sta,
        start_time: settings.start_time,
        off_sta: settings.off_sta,
        off_time: settings.off_time,
        login_run: settings.login_run,
        day_one: settings.day_one,
        user_one: settings.user_one,
        sub_count: settings.sub_count? parseInt(settings.submitNum) : 0,
				workflow_switch: settings.workflow_switch,
        related_projects: settings.related_projects,
        task_info: settings.task_info,
      }).then(res => {
        if(res.data.is_ok == 1){
          this.settingDialogClose()
          Message.success(this.$t('lang.MsgSetSuccess'))
					this.refreshFormList()
        }  
      })
    },
    // 创建表单
    createForm(){
			let title = this.newFormTitle.trim() || this.$t('lang.untitledForm')
      api.updateForm({
        name: title,
        is_push: false,
      }).then(res => {
        if(res.data.is_ok == 1){	
          this.$router.push({
            path: '/newForm',
            query: {
              title,
              formId: res.data.form_main_dbid,
            }
          })
					this.closeNewFormDialog()
        }  
      })
    },
		// 使用模板
		useTemp(item){
			api.cloneTemp({
				id: item.id,
			}).then(res => {
				this.goEditForm(res.data)
			})
		},
  }
}
</script>

<style lang="stylus">
	#header-left{
		display: flex;
		align-items: center;
		flex: 1;
	}
	.container{
		min-width: 1060px !important;

		.side{
			width: auto !important;
			min-width: 180px;
			height: 100%;
			padding: 35px 0 20px;
			background: #fff;
			position relative
			.imgdoc{
				width: 30px;
				height: 30px;
				vertical-align: middle;
				margin-left: 10px;
			}
			.title{
				display: flex;
				justify-content: center
				align-items: center;
				flex-shrink: 0;

				img{
					margin-right: 5px;
					margin-left: 0;
				}
			}
			.menu{
				padding-left: 15px
				margin-top: 30px
			}
			.fenlei{
				height: 37px;
				margin: 0 auto;
				line-height: 37px;
				font-size: 14px;
				color: #6c748a;
				text-align: left;
				margin-bottom: 10px;
				padding-right: 10px;
				cursor: pointer;
				i{
					margin-right: 10px;
					margin-left: 10px;
					color: #a5b5ce;
				}
			}
			.bgcolor{
				background: #f2f5fa;
			}
			.bottom{
				position: absolute
				left: 0
				bottom: 0
				padding: 15px
				width: 100%
			}
		}

		.main-content{
			padding: 15px;
			background: #f2f4f7;
			.content-wrap{
				.content-block{
					background #fff
					border-radius 15px
					padding 20px
					margin-bottom 15px
					&:last-chlid{
						margin-bottom 0
					}
					.block-title{
						display: flex;
						align-items: center;
						justify-content: space-between;
						height 40px
						position relative
						.table-filters{
							position absolute
							top 0
							right 0
							display flex
							.search-input{
								width: 170px;
								margin-left 10px
							}
						}
					}
					.table-row{
						line-height: 55px;
						height: 55px;
						.form-name{
							color: #3d4757;
							font-size: 14px;
							margin-right: 5px;
							white-space: nowrap;
							text-overflow: ellipsis;
						}
						.desc-span{
							color: #767c85;
							font-size: 13px;
						}
						.table-icon{
							font-size: 16px;
							margin-left: 12px;
							cursor: pointer;
						}
						.el-icon-star-on{
							font-size: 20px;
						}
						.more-icon{
							cursor: pointer;
							margin-left: 12px
							font-size: 12px
						}
					}
					.pagination{
						margin-top: 40px;
					}
				}
				.recommend-list{
					display: flex;
					font-size: 12px;
					margin-top: 10px
					overflow-x: auto
					.recommend-item{
						min-width: 130px;
						border: 1px solid #e7e9eb;
						padding: 10px;
						margin-right: 15px;
						cursor: pointer;
						border-radius: 5px
						&:hover{
							box-shadow: 0 0 10px 2px #eee;
						}
						i{
							font-size: 25px
						}
						&:nth-child(3n+1) .icon{
							color: #DC143C
						}
						&:nth-child(3n+2) .icon{
							color: #409EFF
						}
						&:nth-child(3n+3) .icon{
							color: coral
						}
						img{
							width: 30px;
							height: 30px;
						}
						p{
							margin-bottom: 0;
							margin-top: 10px
						}
						&:last-child{
							background #f2f4f7
						}
					}
				}
				.el-tabs__nav-wrap::after{
					display none !important
				}
			}
		}
	}
	
	.el-icon-star-off:hover{
		color: #1989fa;
	}
	.el-icon-star-on{
		color: #fcdf39;	
	}
	.el-table__row{
		.hover-dom{
			display: none;
		}
		&:hover .hover-dom{
			display: inline-block;
		}
	}
	
	.create-dialog{
		.title{
			font-weight: 700;
			font-size: 16px;
		}
		.input-div{
			display: flex;
			padding: 30px 0;
		}
		.title-input{
    	margin-right: 10px;
  	}
		.more-action{
			display: flex;
			align-items: center;
			justify-content: space-around;
			margin-top: 45px;
			.item{
				display: flex;
				flex-direction: column;
				align-items: center;
				i{
					font-size: 25px;
					margin-bottom: 12px;
					color: #1989fa;
					cursor: pointer;
				}
				span{
					color: #767c85;
				}
			}
		}
	} 
	.line{
		font-size: 12px;
		color: #949aae;
	}
	.copy-form-list{
		max-height: 300px;
		.copy-list-item{
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 15px 15px 15px 0;
			.copy{
				margin-left: 10px;
				color: #1989fa;
				cursor: pointer;
			}
		}
	}
	.no-more-data{
		font-size: 14px;
		color: #aaa;
		padding: 15px 0 25px;
	}
</style>
<style scoped>
	.copy-form-dialog >>> .el-dialog__body{
    border-top: 1px solid #e8e8e8;
		padding-top: 0;
		padding-bottom: 0;
		padding-right: 0;
  }
	.setting-dialog >>> .el-dialog__body{
    border-top: 1px solid #e8e8e8;
		text-align: left;
  }
</style>