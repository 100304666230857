<template>
  <div id="login">
    <div v-if="!editFlag">
      <p>
        <label>用户名：</label>
        <el-input v-model="userName"></el-input>
      </p>
      <p>
        <label>密码：</label>
        <el-input v-model="password"></el-input>
      </p>
      <p class="btns">
        <el-button @click="editFlag = true">修改密码</el-button>
        <el-button type="primary" @click="login">登录</el-button>
      </p>
    </div>
    <div class="changePwd" v-else>
      <p>
        <label>用户名：</label>
        <el-input v-model="edit.userName"></el-input>
      </p>
      <p>
        <label>旧密码：</label>
        <el-input v-model="edit.oldPwd"></el-input>
      </p>
      <p>
        <label>新密码：</label>
        <el-input v-model="edit.newPwd"></el-input>
      </p>
      <p class="btns">
        <el-button @click="editFlag = false">取消修改</el-button>
        <el-button type="primary" @click="editPwd">确认修改</el-button>
      </p>
    </div>
  </div>
</template>

<script>
import api from '@/api/api'
import { Message } from 'element-ui'

export default {
  name: 'Login',
  data(){
    return {
      userName: '',
      corpId: '',
      password: '',
      editFlag: false,
      edit:{
        userName: '',
        oldPwd: '',
        newPwd: '',
      }
    }
  },
  created() {
    
  },
  methods:{
    login(){
      // localStorage.setItem('userId', this.userId)
      // localStorage.setItem('corpId', this.corpId)
      // let afterLogin = sessionStorage.getItem('afterLogin')
      // if(afterLogin){
      //   this.$router.push(afterLogin)
      // }else{
      //   this.$router.push('/')
      // }
      api.tempLogin({
        user_name: this.userName,
        password: this.password,
      }).then(res => {
        sessionStorage.setItem('loginFlag', 'ok')
        this.$router.push('/templateMgmt')
      }).catch(err => {
        Message.error('登录失败')
      })
    },
    editPwd(){
      api.tempEditPwd({
        user_name: this.edit.userName,
        old_password: this.edit.oldPwd,
        password: this.edit.newPwd,
      }).then(res => {
        Message.success('修改成功')
        this.editFlag = false
      })
    }
  }
}
</script>

<style scoped>
  #login{
    width: 500px;
    margin: 100px auto;
  }
  #login p{
    display: flex;
    align-items: center;
  }
  .btns{
    justify-content: center;
  }
  #login p label{
    width: 80px;
    text-align: right;
    margin-right: 10px;
  }
</style>