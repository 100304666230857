import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
import Login from '../views/Login.vue'
import FormTemplate from '../views/FormTemplate.vue'
// import WriteForm from '../views/WriteForm.vue'
// import WriteEmbed from '../views/pc/WriteEmbed.vue'
import TemplateMgmt from '../views/TemplateMgmt.vue'
import AnswerDetail from '../views/pc/AnswerDetail.vue'
import mIndex from '../views/mobile/Index.vue'
import Ceshi from "@/views/pc/Ceshi";
// import mLogin from '../views/mobile/Login.vue'
import mFormTemplate from '../views/mobile/FormTemplate.vue'
// import mWriteForm from '../views/mobile/WriteForm.vue'
import mCopyForm from '../views/mobile/CopyForm.vue'
import util from '../util'

Vue.use(VueRouter)
const isMobile = util.isMobile()

const routes = [
  {
    path: '/',
    name: 'Index',
    component: isMobile? mIndex : Index,
    meta: {
      keepAlive: true,
    }
  },
  {
    path: '/ceshi',
    name: 'Ceshi',
    component: Ceshi
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/collectDetail',
    name: 'CollectDetail',
    component: isMobile? () => import(/* webpackChunkName: "collectDetail" */ '../views/mobile/CollectDetail.vue') : () => import(/* webpackChunkName: "collectDetail" */ '../views/CollectDetail.vue')
  },
  {
    path: '/template',
    name: 'Template',
    component: isMobile? mFormTemplate : FormTemplate
  },
  {
    path: '/newForm',
    name: 'NewForm',
    component: isMobile? () => import(/* webpackChunkName: "newFrom" */ '../views/mobile/NewForm.vue') : () => import(/* webpackChunkName: "newFrom" */ '../views/NewForm.vue')
  },
  {
    path: '/writeForm',
    name: 'WriteForm',
    component: isMobile? () => import(/* webpackChunkName: "writeForm" */ '../views/mobile/WriteForm.vue') : () => import(/* webpackChunkName: "writeForm" */ '../views/WriteForm.vue')
  },
  {
    path: '/writeEmbed',
    name: 'WriteEmbed',
    component: () => import(/* webpackChunkName: "writeEmbed" */ '../views/pc/WriteEmbed.vue')
  },
  {
    path: '/copyForm',
    name: 'CopyForm',
    component: mCopyForm,
  },
  {
    path: '/templateMgmt',
    name: 'TemplateMgmt',
    component: TemplateMgmt,
  },
  {
    path: '/answerDetail',
    name: 'AnswerDetail',
    component: AnswerDetail,
  },
]

const router = new VueRouter({
  mode:"history",
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  store.state.toPage = to
  if(!util.judgeLogin()){
    if (['WriteForm', 'TemplateMgmt', 'WriteEmbed'].includes(to.name)){
      // 填写页 模版管理 优先登陆 如果没有信息走匿名登陆
      util.login(next, true)
    }else {
      util.login(next)
    }
  }else{
    next()
  }
})

export default router
