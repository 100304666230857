<template>
  <div class="setting">
    <van-cell 
      is-link 
      v-if="!settings.is_push"
      :title="$t('lang.newFormSettingRegularRelease')" 
      arrow-direction="down" 
      :value="startSta.name"
      @click="startActionShow = true">
    </van-cell>
    <van-cell 
      is-link 
      v-if="settings.start_sta == 2 && !settings.is_push" 
      :title="$t('lang.newFormSettingSendTime')" 
      arrow-direction="down" 
      :value="settings.start_time"
      @click="startDatetimePopupShow = true">
    </van-cell>

    <van-cell 
      is-link 
      v-if="settings.sta != 2"
      :title="$t('lang.newFormSettingStopTime')"  
      arrow-direction="down" 
      :value="endSta.name"
      @click="endActionShow = true">
    </van-cell>
    <van-cell 
      is-link 
      v-if="settings.off_sta == 1 && settings.sta != 2" 
      :title="$t('lang.newFormSettingTime')"  
      arrow-direction="down" 
      :value="settings.off_time"
      @click="endDatetimePopupShow = true">
    </van-cell>


    <van-cell-group :title="$t('lang.newFormSettingWriteUser')" >
      <van-cell center :title="$t('lang.newFormSettingIsLogin')">
        <template #right-icon>
          <van-switch v-model="settings.login_run" size="24" />
        </template>
      </van-cell>
    </van-cell-group>
    
    <van-cell-group :title="$t('lang.newFormSettingAuthority')">
      <van-cell center :title="$t('lang.newFormSettingOneDay')">
        <template #right-icon>
          <van-switch v-model="settings.day_one" size="24" />
        </template>
      </van-cell>
      <!-- <van-cell center title="允许填写者再次修改">
        <template #right-icon>
          <van-switch v-model="settings.run_edit" size="24" />
        </template>
      </van-cell> -->
      <van-cell center :title="$t('lang.newFormSettingOneUser')">
        <template #right-icon>
          <van-switch v-model="settings.user_one" size="24" />
        </template>
      </van-cell>
      <van-cell center :title="$t('lang.newFormSettingWriteSum')">
        <template #right-icon>
          <van-switch v-model="settings.sub_count" size="24" />
        </template>
      </van-cell>
      <van-field clearable v-show="settings.sub_count" v-model="settings.submitNum" input-align="right" label="最多收集份数" />
    </van-cell-group>
    
<!--    <van-cell-group :title="$t('lang.newFormSettingWriteSock')">-->
<!--      <van-cell center :title="$t('lang.newFormSettingWriteSockTitle')">-->
<!--        <template #right-icon>-->
<!--          <van-switch v-model="settings.news_send" size="24" />-->
<!--        </template>-->
<!--      </van-cell>-->
<!--    </van-cell-group>-->

    <slot name="footer"></slot>

    <van-popup 
      v-model="startDatetimePopupShow" 
      round 
      position="bottom"
      :style="{ height: '50%' }">
      <van-datetime-picker
        v-model="startDateTime"
        :min-date="minDate"
        type="datetime"
        :title="$t('lang.newFormPlaseDate')"
        @confirm="startDatetimeConfirm"
        @cancel="startDatetimePopupShow = false"
      />
    </van-popup>
    <van-popup 
      v-model="endDatetimePopupShow" 
      round 
      position="bottom"
      :style="{ height: '50%' }">
      <van-datetime-picker
        v-model="endDateTime"
        :min-date="minDate"
        type="datetime"
        :title="$t('lang.newFormPlaseDate')"
        @confirm="endDatetimeConfirm"
        @cancel="endDatetimePopupShow = false"
      />
    </van-popup>
    
    <van-action-sheet 
      v-model="startActionShow" 
      :actions="startActions" 
      @select="startActionSelect" 
      :cancel-text="$t('lang.ButtonCall')"
      close-on-click-action/>

    <van-action-sheet 
      v-model="endActionShow" 
      :actions="endActions" 
      @select="endActionSelect" 
      :cancel-text="$t('lang.ButtonCall')"
      close-on-click-action/>
  </div>
</template>

<script>
import { Toast } from 'vant'
const moment = require('moment')

export default {
  name: 'Setting',
  data(){
    return{
      startActionShow: false,
      startSta: {name: '不限制', value: 1},
      startActions: [{name: '不限制', value: 1}, {name: '定时发布', value: 2}],
      endActionShow: false,
      endSta: {name: '不限制', value: 0},
      endActions: [{name: '不限制', value: 0}, {name: '定时停止', value: 1}],
      startDatetimePopupShow: false,
      endDatetimePopupShow: false,
      startDateTime: '',
      endDateTime: '',
      minDate: new Date(),
      settings: {
        start_sta: 1,
        start_time: '',
        off_sta: 0,
        off_time: '',
        login_run: false,
        day_one: false,
        // run_edit: false,
        user_one: false,
        sub_count: false,
        submitNum: 10,
        news_send: false,
        sta: 1,
        is_push: false,
      },
    }
  },
  props: {
    editFlag:{
      type: Boolean,
      default: false,
    },
    formSettings:{
      type: Object,
      default(){
        return {}
      }
    }
  },
  watch: {
    formSettings(){
      if(this.editFlag){
        this.formatSettings()
      }
    }
  },
  created(){
    this.startActions = [{name: this.$t('lang.newFormSettingNoRestrictions'), value: 1}, {name: this.$t('lang.newFormSettingRegularRelease'), value: 2}]
    this.startSta = this.startActions[0]
    this.endActions = [{name: this.$t('lang.newFormSettingNoRestrictions'), value: 0}, {name: this.$t('lang.newFormSettingStopTime'), value: 1}]
    this.endSta = this.endActions[0]
    this.formatSettings()
  },
  methods: {
    startActionSelect(item){
      this.startSta = item
      this.settings.start_sta = item.value
    },
    endActionSelect(item){
      this.endSta = item
      this.settings.off_sta = item.value
    },
    startDatetimeConfirm(value){
      this.startDatetimePopupShow = false
      this.settings.start_time = moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    endDatetimeConfirm(value){
      this.endDatetimePopupShow = false
      this.settings.off_time = moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    confirm(){
      if(this.settings.start_sta == 1){
        delete this.settings.start_time
      }else{
        if(this.settings.start_time){
          this.settings.start_time = moment(this.settings.start_time).format()
        }else{
          Toast(this.$t('lang.newFormSettingSendTime'))
          return
        }
      }

      if(this.settings.off_sta == 0){
        delete this.settings.off_time
      }else{
        if(this.settings.off_time){
          this.settings.off_time = moment(this.settings.off_time).format()
        }else{
          Toast(this.$t('lang.formStopTIme'))
        }
      }
      this.$emit('confirm', this.settings)
    },
    formatSettings(){
      this.settings = {...this.formSettings}
      if(this.settings.sub_count == 0){
        this.settings.sub_count = false
      }else{
        this.settings.submitNum = this.settings.sub_count
        this.settings.sub_count = true
      }
      if(this.settings.start_sta == 1){
        this.settings.start_time = ''
      }else{
        this.settings.start_time = moment(this.settings.start_time).format('YYYY-MM-DD HH:mm:ss')
      }
      if(this.settings.off_sta == 0){
        this.settings.off_time = ''
      }else{
        this.settings.off_time = moment(this.settings.off_time).format('YYYY-MM-DD HH:mm:ss')
      }
      // console.log(this.settings)
    },
  }
}
</script>

<style scoped>
  .setting{
    width: 100%;
    min-height: calc(100vh - 70px);
    overflow-y: scroll;
    background: #f9fafc;
    text-align: left;
  }
</style>