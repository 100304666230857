<template>
  <div class="qrcode-container" v-show="qrcodeShow">
    <van-nav-bar
      :title="$t('lang.Index_Invite')"
      left-arrow
      @click-left="qrcodeShow = false"
    />
    <vue-qr class="qrcode" ref="Qrcode" :text="qrcodeText" :size="190" :margin="10"></vue-qr>
    <p class="title">{{formTitle}}</p>
    <p class="tip">{{$t('lang.mIndexInvite')}}</p>
  </div>
</template>

<script>
import VueQr from 'vue-qr'

export default {
  components: { VueQr },
  data(){
    return{
      qrcodeShow: false
    }
  },
  watch: {
    qrcodeShow(val){
      if(!val){
        this.$emit('closeQrcode')
      }
    }
  },
  props: {
    formTitle: {
      type: String,
      default: '',
    },
    shareUrl: {
      type: String,
      default: '',
    },
  },
  created(){
    this.init()
  },
  methods: {
    init(){
      this.qrcodeShow = true
      let index = this.shareUrl.indexOf('\n')
      this.qrcodeText = index > -1 ? this.shareUrl.substring(0,index) : this.shareUrl
    }
  }
}
</script>

<style scoped>
  .qrcode-container{
    text-align: center;
    width: 100%;
    height: 100vh;
    background: #fff;
    padding: 0 1em;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  .qrcode{
    display: flex;
    justify-content: center;
    margin: 50px 0;
  }
  .qrcode-container .title{
    color: #3d4757;
    font-size: 16px;
  }
  .qrcode-container .tip{
    color: #767c85;
    font-size: 14px;
    margin-bottom: 40px;
  }
</style>