import Vue from 'vue'
import api from '@/api/api'
const moment = require('moment')

Vue.mixin({
  name: 'mixin',
  data(){
    return{
      fileLimit: 10,
    }
  },
  filters: {

  },
  methods: {
    internationFormat(params){
			return moment(params).format('YYYY-MM-DD HH:mm')
		},
    isOldFile(filePath){
      return filePath.indexOf('public/file/') > -1
    },
    formatFileName(url){
      let path = decodeURIComponent(url)
      if(path && path.indexOf('https://') > -1){
        let pathArr = path.split('?')[0].split('/')
        let fileName = pathArr[pathArr.length-1]
        return fileName.substring(fileName.indexOf('@')+1)
      }
      return path
    },
    async uploadFile(file, type){
      const sizeLimit = file.size / 1024 / 1024 < this.fileLimit;
      if (!sizeLimit) {
        Message.error(this.$t('lang.newFormTenM'));
        return
      }

      const fileName = `${file.uid}@${file.name}`
      if(type == 'system'){
        let res = await api.s3SystemUpload({ name: fileName })
        return this.upload(file.raw, fileName, res.data.URL)
      }else{
        let res = await api.s3UserUpload({ name: fileName })
        return this.upload(file.raw, fileName, res.data.URL)
      }
    },
    async upload(file, fileName, url, needDown){
      await api.s3Put(file, url)
      let res = await api.s3Down({name: fileName})
      return {
        img: fileName,
        img_url: res.data,
      }
    },
    downloadImg(imgName){
      api.s3Down({
        name: imgName
      }).then(res => {
        return res.data
      })
    }
  },
})
