<template>
  <el-header class="header">
    <slot name="left"></slot>
    <div id="header-right">

      <el-popover
        placement="bottom"
        width="400"
        trigger="click">
          <el-table
          :show-header="false"
          :data="messageList"
          stripe>
          <el-table-column
            align="left"
            prop="value"
            label="内容">
          </el-table-column>
          <el-table-column
            align="right"
            prop="CreatedAt"
            label="时间">
          </el-table-column>
        </el-table>
        <!-- <i class="el-icon-chat-line-round header-message"
          slot="reference"
          @click="queryMessageList"></i> -->
      </el-popover>
      <!-- <span class="setting" @click="drawer=true">{{$t('lang.Header_set')}}</span>
      <span class="user" v-if="userId">{{$t('lang.Header_id')}}：{{ userId }}</span> -->
      <!-- <el-link class="" type="primary" @click="logout">退出</el-link> -->
    </div>
    <el-drawer
        :title="$t('lang.Header_set')"
        :visible.sync="drawer"
        direction="rtl"
        :modal="false"
        size="400px">
      <el-row>
        <el-col :span="15">
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item :label="$t('lang.Lag')">
              <el-select size="small" v-model="form.lang" placeholder="请选择">
                <el-option label="中文繁體" value="zh-CN"></el-option>
                <el-option label="English" value="en-US"></el-option>
                <el-option label="日本語" value="jap"></el-option>
              </el-select>
              </el-form-item>
            </el-form>

        </el-col>
      </el-row>
    </el-drawer>
  </el-header>

</template>

<script>
import api from '@/api/api'

export default {
  name: 'Header',
  data(){
    return{
      messageList: [],
      userId: '',
      drawer:false,
      form:{lang: ''},
    }
  },
  watch: {
    form: {
      handler(val){
        this.$i18n.locale = val.lang
        localStorage.setItem('i18n', val.lang)
      },
      deep: true,
    }
  },
  created(){
    this.userId = sessionStorage.getItem('userId')
    let i18n = localStorage.getItem('i18n')
    this.form.lang = i18n == 'undefined' ? 'en-US' : i18n
  },
  methods: {
    //关闭设置
    handleClose(done) {
      this.$i18n.locale =this.form.lang
      localStorage.setItem('i18n', this.form.lang)
      done();
    },
    queryMessageList(){
			api.queryMessage({
				limit: 10,
				page: 1,
				order: 'id',
				id: this.userId,
			}).then(res => {
        this.messageList = res.data
			})
    },
    logout(){
      localStorage.setItem('userId', '')
      localStorage.setItem('afterLogin', this.$router.history.current.fullPath)
      this.$router.push('/login')
    },
  }
}
</script>

<style scoped>
  .header{
    width: 100%;
    min-width: 890px;
    border-bottom: 1px solid #e7e9eb;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    position: fixed;
    top: 0;
    z-index: 999;
  }
  #header-right{
    display: flex;
    align-items: center;
  }
  .setting{
    margin-right: 20px;
    font-size: 12px;
    cursor: pointer;
  }
  .setting:hover{
    color: #40a9ff;
    text-decoration: underline;
  }
  .user{
    margin-right: 10px;
    font-size: 12px;
  }
  .header-message{
		font-size: 22px;
		color: #767c85;
		cursor: pointer;
    margin-right: 10px;
	}
</style>