<template>
  <div class="cover-container create-form">
    <p>{{$t('lang.formTitle')}}</p>
    <van-field v-model="newFormTitle" :placeholder="$t('lang.formWriteTitle')" />
    <div class="btns">
      <van-button class="half-btn" @click="createFormCancel">{{$t('lang.ButtonCall')}}</van-button>
      <van-button class="half-btn" type="info" @click="createForm">{{$t('lang.formCreatedSub')}}</van-button>
    </div>
    <van-divider class="divider">{{$t('lang.formMoreform')}}</van-divider>
    <div class="more-action">
      <span class="item" @click="toCopyForm">
        <van-icon name="description" />
        <span>{{$t('lang.formCopy')}}</span>
      </span>
    </div>
  </div>
</template>

<script>
import api from '@/api/api'

export default {
  data(){
    return{
      newFormTitle: ''
    }
  },
  watch: {
  },
  props: {
    
  },
  created(){
    
  },
  methods: {
    createFormCancel(){
      this.newFormTitle = ''
      this.$emit('closeCreateFormMask')
    },
    createForm(){
      api.updateForm({
        name: this.newFormTitle,
        is_push: false,
      }).then(res => {
        if(res.data.is_ok == 1){
          this.$router.push({
            path: '/newForm',
            query: {
              title: this.newFormTitle,
              formId: res.data.form_main_dbid,
            }
          })
          this.createFormCancel()
        }  
      })
    },
    toCopyForm(){
      this.createFormCancel()
      this.$router.push('/copyForm')
    },
  }
}
</script>

<style scoped>
  .cover-container{
    width: 100%;
    height: 100vh;
    background: #fff;
    padding: 0 1em;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  .create-form p {
    margin: 0;
    padding: 1em;
    text-align: left;
  }
  .create-form .btns{
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .create-form .btns .half-btn{
    width: 48%;
  }
  .divider{
    margin: 60px 0 30px;
  }
  .more-action{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0 40px;
  }
  .more-action .item{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
  }
  .more-action .item .van-icon,
  .more-action .item .el-icon-link{
    font-size: 30px;
    color: #409EFF;
    margin-bottom: 10px;
  }
</style>