<template>
  <div>
    <el-dialog 
      width="750px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="createdVisible"
      @close="close">
      <div class="share">
        <div class="qrcode">
          <VueQr ref="Qrcode" :text="shareUrl" :size="170" :margin="10"></VueQr>
          <el-button icon="el-icon-download" @click="saveQrcode">{{$t('lang.newFormSubQrSave')}}</el-button>
        </div>
        <div class="copy-link">
          <p>{{$t('lang.shareForm')}}</p>
          <p>{{$t('lang.inviteToWrite')}}</p>
          <div class="link-wrap">
            <div class="link">{{shareUrl}}</div>
            <el-button type="primary" class="clipboard" :data-clipboard-text="shareUrl">{{$t('lang.formCopyFe')}}</el-button>
          </div>
          <el-button type="primary" class="clipboard" :data-clipboard-text="embedHTML">{{$t('lang.copyEmbedHTML')}}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import store from '@/store'
import VueQr from 'vue-qr'

export default {
  name: 'ShareDialog',
  components: { VueQr },
  data(){
    return {
      createdVisible: false,
      flag: '',
      embedHTML: '',
    }
  },
  props: {
    share: {
      type: Boolean,
      default: false,
    },
    shareUrl: {
      type: String,
      default: '',
    },
    index: {
      type: Boolean,
      default: false,
    }
  },
  watch:{
    share(val){
      this.createdVisible = val
      this.embedHTML = `<iframe style="border:none;" width="100%" src="${this.shareUrl.replace('writeForm', 'writeEmbed')}"></iframe>`
    },
  },
  created(){
    this.flag = sessionStorage.getItem('createFlag')
  },
  methods:{
    close(){
      if(this.index){
        this.$emit('close')
      }else{  
        this.backToIndex()
      }
    },
    backToIndex(){
      if(this.flag == 'createTemplate'){
        this.$router.push('/templateMgmt')
      }else{
        store.state.refreshFlag = 'NewForm'
        this.$router.push('/')
      }
      
    },
    dataURLtoBlob() {
      let dataurl = document.querySelector('.qrcode img').src
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    getObjectURL(file) {
      //创建下载的链接
      var link = document.createElement("a");
      // 创建url
      var href = window.URL.createObjectURL(file);
      link.href = href;
      //下载后文件名
      link.download = "share.png";
      document.body.appendChild(link);
      //点击下载
      link.click();
      //下载完成移除元素
      document.body.removeChild(link);
      //释放掉blob
      window.URL.revokeObjectURL(href);
    },
    saveQrcode(){
      this.getObjectURL(this.dataURLtoBlob())
    }
  }
}
</script>

<style lang="stylus" scoped>
  .share{
    display: flex
    justify-content: flex-start
    .qrcode{
      width: 200px
    }
    .copy-link{
      width: calc(100% - 200px)
      text-align: left
      p:first-child{
        font-weight: bold
        font-size: 16px
        margin-bottom: 30px
      }
      .link-wrap{
        display: flex
        align-items: center
        margin-top: 30px
        margin-bottom: 14px;
        .link{
          flex: 1
          height: 40px
          line-height: @height
          padding: 0 10px
          white-space nowrap
          text-overflow: ellipsis
          overflow: hidden
          background: #eee
        }
      }
    }
  }
</style>