<template>
  <div class="form-template scroll-wrap">
    <Header>
      <div slot="left" id="header-left">
        <i class="el-icon-arrow-left" @click="goBack"></i>
        <img class="imgdoc" src="../assets/images/form-icon.png" />
        {{$t('lang.Index_created')}}
      </div>
    </Header>
    <div class="scroll-container">
      <el-main class="main">
        <div class="template-choose">
          <p class="label">        {{$t('lang.formTemplate_type')}}</p>
          <el-row :gutter="12">
            <el-col :span="8">
              <el-card class="type-card" shadow="hover"
                @click.native="openNewFormDialog">
                <i class="el-icon-circle-plus"></i>  {{$t('lang.formTemplate_form')}}
              </el-card>
            </el-col>
          </el-row>
        </div>
      </el-main>
     <el-main class="main temp-container">
       <ul class="temp-list">
         <li v-for="item in tempList" :key="item.id" @click="useTemp(item)">
           <p>{{item.name}}</p>
           <div v-if="item.details[0]">{{item.details[0].title}}</div>
           <div v-if="item.details[1]">{{item.details[1].title}}</div>
           <div v-if="item.details[2]">{{item.details[2].title}}</div>
         </li>
       </ul>
     </el-main>
    </div>

    <el-dialog
      :visible.sync="newFormDialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeNewFormDialog"
      width="450px" class="create-dialog">
      <p>{{$t('lang.formCreateform')}}</p>
      <div class="input-div">
        <el-input class="title-input" v-model="newFormTitle" :placeholder="$t('lang.formWriteTitle')"></el-input>
        <el-button type="primary" @click="createForm">{{$t('lang.formCreatedSub')}}</el-button>
      </div>
      <el-divider><span class="line">{{$t('lang.formMoreform')}}</span></el-divider>
			<div class="more-action">
				<span class="item">
					<i class="el-icon-document-copy"  @click="openCopyFormDialog"></i>
					<span>{{$t('lang.formCopy')}}</span>
				</span>
			</div>
    </el-dialog>

    <el-dialog
			:title="$t('lang.formIsOne')"
      :visible.sync="copyFormVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeCopyFormDialog"
      width="600px" class="copy-form-dialog">
			<ul class="copy-form-list" 
				v-if="copyFormList.length > 0"
				v-infinite-scroll="queryCopyList" 
				:infinite-scroll-disabled="infiniteScroll"
				style="overflow:auto">
				<li v-for="item in copyFormList" :key="item.id" class="copy-list-item">
					<span>{{ item.name }}</span>
					<div>
						<span>{{ internationFormat(item.UpdatedAt) }}{{$t('lang.Index_Svae')}}</span>
						<span class="copy" @click="copyFormToEdit(item)">{{$t('lang.formCopyFe')}}</span>
					</div>
				</li>
				<li v-if="infiniteScroll">
					<el-divider><span class="line">{{$t('lang.formNodeDtta')}}</span></el-divider>
				</li>
			</ul>
			<el-empty v-else :description="$t('lang.NoData')"></el-empty>
			
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/api'
import Header from '@/components/Header'

export default {
  name: 'FormTemplate',
  components: {
    Header,
  },
  data(){
    return{
      userId: '',
      newFormDialogVisible: false,
      newFormTitle: '',
      tempList: [],
      copyFormVisible: false,
      copyFormList: [],
			copyListPage: 0,
			infiniteScroll: false,
    }
  },
  created(){
    this.userId = sessionStorage.getItem('userId')
    this.queryTempList(1)
  },
  methods:{
    // 返回
    goBack(){
      this.$router.go(-1)
    },
    // 打开标题输入框
    openNewFormDialog(){
      this.newFormDialogVisible = true
    },
    // 关闭标题输入框
    closeNewFormDialog(){
      this.newFormTitle = ''
    },
    // 创建表单
    createForm(){
      api.updateForm({
        name: this.newFormTitle,
        is_push: false,
        user_id: this.userId,
      }).then(res => {
        if(res.data.is_ok == 1){
          this.$router.push({
            path: '/newForm',
            query: {
              title: this.newFormTitle,
              formId: res.data.form_main_dbid,
            }
          })
        }  
      })
    },
    queryTempList(page){
      let soso = 'temp_lang='
      switch(this.$i18n.locale){
        case 'zh-CN':
          soso = `${soso}3`
          break
        case 'en-US':
          soso = `${soso}2`
          break
        case 'jap':
          soso = `${soso}1`
          break
      }
			api.queryTemp({
				limit: 9999,
				page,
				order: 'updated_at desc',
        soso,
			}).then(res => {
				this.tempList = res.data
			})
		},
    // 使用模板
		useTemp(item){
			api.cloneTemp({
				id: item.id,
			}).then(res => {
        this.goEditForm(res.data)
			})
		},
    queryCopyList(){
			if(this.copyListPage == 0){
				this.copyFormList = []
			}
			this.copyListPage++
			api.queryForm({
				limit: 10,
				page: this.copyListPage,
				order: 'updated_at desc',
				// id: this.userId,
				// corp_id: this.corpId,
			}).then(res => {
        this.infiniteScroll = res.data.list.length < 10
				this.copyFormList = this.copyFormList.concat(res.data.list)
			})
		},
    openCopyFormDialog(){
			this.copyFormVisible = true
			this.copyListPage = 0
			this.queryCopyList()
		},
		closeCopyFormDialog(){
			this.copyFormVisible = false
		},
		// 复制已有表单
		copyFormToEdit(item){
			api.copyForm({
				id: item.id
			}).then(res => {
				this.closeCopyFormDialog()
				this.closeNewFormDialog()
				this.goEditForm(res.data)
			})
		},
    goEditForm(formId){
			this.$router.push({
				path: '/newForm',
				query: {
					formId,
					editFlag: true,
				}
			})
		},
  }
}
</script>

<style scoped>
  .scroll-container{
    min-width: 900px;
    /* display: flex;
    justify-content: center; */
  }
  .main{
    /* width: 900px; */
    display: flex;
    justify-content: center;
    /* min-height: 100%; */
  }
  .template-choose{
    background: #fff;
    width: 850px;
    margin: auto;
  }
  .template-choose .label{
    font-size: 16px;
    font-weight: 700;
    color: #3d4757;
    text-align: left;
  }
  .type-card{
    cursor: pointer;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 20px;
  }
  .type-card i{
    margin-right: 10px;
    color: #DADEE5;
  }
  .create-dialog p{
    font-weight: 700;
    font-size: 16px;
  }
  .create-dialog .input-div{
    display: flex;
    padding: 30px 0;
  }
  .create-dialog .title-input{
    margin-right: 10px;
  }
  .temp-container{
    background: rgb(245, 245, 249);
    min-height: calc(100vh - 250px);
  }
  .temp-list{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 850px;
  } 
  .temp-list li{
    background: #fff;
    width: 150px;
    height: 210px;
    margin: 0 25px 25px 0;
    cursor: pointer;
  }
  .temp-list li:hover{
    box-shadow: 0 4px 16px 0 rgb(192 198 207 / 50%);
  }
  .temp-list li:nth-child(5n){
    margin-right: 0;
  }
  .temp-list li p,
  .temp-list li div{
    padding: 5px 8px 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .temp-list li p{
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #f5f7f9;
  }
  .temp-list li div{
    font-size: 12px;
    color: #999;
    text-align: left;
  }
  .line{
		font-size: 12px;
		color: #949aae;
	}
  .create-dialog .more-action{
		display: flex;
		align-items: center;
		justify-content: space-around;
		margin-top: 45px;
	}
	.create-dialog .more-action .item{
		display: flex;
		flex-direction: column;
		align-items: center;
	}
	.create-dialog .more-action .item i{
		font-size: 25px;
		margin-bottom: 12px;
		color: #1989fa;
		cursor: pointer;
	}
	.create-dialog .more-action .item span{
		color: #767c85;
	}
	.copy-form-dialog >>> .el-dialog__body{
    border-top: 1px solid #e8e8e8;
		padding-top: 0;
		padding-bottom: 0;
		padding-right: 0;
  }
	.copy-form-list{
		max-height: 300px;
	}
	.copy-list-item{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 15px 15px 15px 0;
	}
	.copy-list-item div .copy{
		margin-left: 10px;
		color: #1989fa;
		cursor: pointer;
	}
</style>