<template>
  <div class="search-bar" :class="resultShow? 'full': ''">
    <div class="search">
      <van-search 
        class="search-input"
        clearable
        ref="search"
        v-model="search"
        @focus="onFocus"
        @blur="onBlur"
        @input="querySearchList('search')"
        :placeholder="$t('lang.SearchForm')" />
        <!-- <van-icon name="setting-o" @click="drawer = true" /> -->
    </div>
    
    <div v-if="resultShow" class="result-container">
      <ul class="result"
        v-infinite-scroll="querySearchList" 
        :infinite-scroll-disabled="infiniteScroll"
        v-if="searchList.length > 0">
        <li v-for="item in searchList" :key="item.id" @click="goDetail(item)">
          <p>{{ item.name }}</p>
          <p class="time">{{ internationFormat(item.UpdatedAt) }}{{$t('lang.Index_Svae')}}</p>
        </li>
      </ul>
      <van-empty v-else :description="$t('lang.SearchNo')" />
    </div>

    <el-drawer
      size="90%"
        :title="$t('lang.Header_set')"
        :visible.sync="drawer"
        direction="rtl"
        :modal="false">
      <el-row>
        <el-col :span="15">
            <el-form ref="form" :model="form" label-width="80px">
              <el-form-item :label="$t('lang.Lag')">
              <el-select size="small" v-model="form.lang" placeholder="请选择">
                <el-option label="中文繁體" value="zh-CN"></el-option>
                <el-option label="English" value="en-US"></el-option>
                <el-option label="日本語" value="jap"></el-option>
              </el-select>
              </el-form-item>
            </el-form>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>

<script>
import api from '@/api/api'

export default {
  name: 'SearchBar',
  data(){
    return {
      search: '',
      currentPage: 0,
      search: '',
      searchList: [],
      infiniteScroll: false,
      nodata: false,
      resultShow: false,
      showPopover: false,
      drawer: false,
      form:{lang: ''}
    }
  },
  watch: {
    resultShow(val){
      this.$emit('searching', val)
    },
    form: {
      handler(val){
        this.$i18n.locale = val.lang
        localStorage.setItem('i18n', val.lang)
      },
      deep: true,
    }
  },
  created(){
    let i18n = localStorage.getItem('i18n')
    this.form.lang = i18n == 'undefined' ? 'en-US' : i18n
  },
  methods: {
    querySearchList(type){
      if(type == 'search'){
        this.currentPage = 0
      }
      if(this.search != ''){
        this.currentPage++
        api.queryFormNoLoading({
          limit: 10,
          page: this.currentPage,
          name: this.search,
          order: 'id desc',
          // id: localStorage.userId,
          // corp_id: +localStorage.corpId,
        }).then(res => {
          this.nodata = res.count == 0
          this.infiniteScroll = res.data.list.length < 10
          if(this.currentPage == 1){
            this.searchList = []
          }
          this.searchList = this.searchList.concat(res.data.list)
        })
      }else{
        this.searchList = []
      }
    },
    onFocus(){
      this.resultShow = true
    },
    onBlur(){
      if(this.search == ''){
        this.resultShow = false
      }
    },
    goDetail(item){
      this.search = ''
      this.searchList = []
      this.resultShow = false
      this.$emit('goDetail', item)
    },
    handleClose(done) {
      this.$i18n.locale =this.form.lang
      localStorage.setItem('i18n', this.form.lang)
      done();
    },
  }
}
</script>

<style scoped>
  .full{
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .result-container{
    overflow-y: auto;
  }
  .result{
    padding: 15px;
  }
  .result li{
    font-size: 14px;
    text-align: left;
    background: #fff;
    margin-bottom: 15px;
    padding: 1px 10px;
    border-radius: 8px;
  }
  .time{
    font-size: 12px;
    color: #aeb5c0;
  }
  .search{
    background: #fff;
    display: flex;
    align-items: center;
    /* padding-right: 15px; */
  }
  .search-input{
    flex: 1;
  }
</style>