<template>
  <div id="mobile-form-template">
    <div class="block new" v-if="!newFormDialogVisible">
      <p class="block-title">{{$t('lang.Index_created')}}</p>
      <div class="block-content">
        <div class="type-card" @click="createMask = true">
          {{$t('lang.Index_Form')}}
        </div>
      </div>
    </div>
    <div class="block">
      <p class="block-title">{{$t('lang.template')}}</p>
      <ul class="temp-list">
        <li v-for="item in tempList" :key="item.id" @click="useTemp(item)">
          <p>{{item.name}}</p>
          <div v-if="item.details[0]">{{item.details[0].title}}</div>
          <div v-if="item.details[1]">{{item.details[1].title}}</div>
          <div v-if="item.details[2]">{{item.details[2].title}}</div>
        </li>
      </ul>
    </div>
    <!-- <div class="create" v-else>
      <p>表单名称</p>
      <van-field v-model="newFormTitle" placeholder="请输入名称" />
      <van-button class="create-btn" type="info" @click="createForm">空白创建</van-button>
    </div> -->

    <CreateFormMask v-if="createMask" @closeCreateFormMask="closeCreateFormMask" />
  </div>
</template>

<script>
import api from '@/api/api'
import CreateFormMask from '@/components/mobile/CreateFormMask'

export default {
  name: 'FormTemplate',
  components: {
    CreateFormMask,
  },
  data(){
    return{
      userId: '',
      newFormDialogVisible: false,
      newFormTitle: '',
      createMask: false,
      tempList: [],
    }
  },
  created(){
    this.userId = sessionStorage.getItem('userId')
    this.queryTempList(1)
  },
  methods: {
    closeCreateFormMask(){
      this.createMask = false
    },
    queryTempList(page){
      let soso = 'temp_lang='
      switch(this.$i18n.locale){
        case 'zh-CN':
          soso = `${soso}3`
          break
        case 'en-US':
          soso = `${soso}2`
          break
        case 'jap':
          soso = `${soso}1`
          break
      }
			api.queryTemp({
				limit: 9999,
				page,
				order: 'updated_at desc',
        soso,
			}).then(res => {
				this.tempList = res.data
			})
		},
    // 使用模板
		useTemp(item){
			api.cloneTemp({
				id: item.id,
			}).then(res => {
				this.$router.push({
          path: '/newForm',
          query: {
            formId: res.data,
            editFlag: true,
          }
        })
			})
		},
  }
}
</script>

<style scoped>
  #mobile-form-template{
    text-align: left;
    background: #fff;
  }
  .block{
    padding: 0 15px;
    background: #fff;
  }
  .block.new{
    border-bottom: 10px solid #f9fafc;
  }
  .block-title{
    margin: 0;
    padding: 1em 0;
    font-weight: 600;
  }
  .block-content{
    padding: 1em 0;
    display: flex;
    justify-content: space-between;
  }
  .type-card{
    width: 47%;
    height: 80px;
    box-shadow: 0 0 5px 1px #eee;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .create{
    width: 100%;
    height: 100vh;
    background: #fff;
    padding: 0 1em;
  }
  .create p {
    margin: 0;
    padding: 1em;
  }
  .create .create-btn{
    margin-top: 30px;
    width: 100%;
  }
  .temp-list{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .temp-list li{
    box-shadow: 0 1px 3px 1px rgb(0 0 0 / 6%);
    width: calc((100% - 34px)/3);
    height: 144px;
    font-size: 12px;
    margin-bottom: 40px;
  }
  .temp-list li:nth-child(3n+2){
    margin: 0 17px;
  }
  .temp-list li p,
  .temp-list li div{
    
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .temp-list li p{
    margin: 0;
    font-weight: bold;
    border-bottom: 1px solid rgba(0,0,0,.1);
    padding: 10px 8px 10px;
  }
  .temp-list li div{
    color: #999;
    text-align: left;
    padding: 5px 8px 5px;
  }
</style>