import gohttp from './gohttp'

export default {
  // 查询上架模板
  queryTemp(params) {
    return gohttp.gopost( '/form/form_temp', params )
  },
  cloneTemp(params) {
    return gohttp.gopost( '/form/form_temp_clone', params )
  },
  // 修改模板用，自定义错误提示
  updateForm1(params) {
    return gohttp.gopost( '/form/form_main_update', params, false, true )
  },
  // 查询表单
  queryForm(params) {
    return gohttp.gopost( '/form/form_main_sel', params )
  },
  queryFormNoLoading(params) {
    return gohttp.gopost( '/form/form_main_sel', params, true )
  },
  // 查询单条表单信息
  queryOneForm(params) {
    return gohttp.gopost( '/form/form_main_one', params, false, true )
  },
  // 删除表单
  deleteForm(params) {
    return gohttp.gopost( '/form/form_main_del', params )
  },
  // 添加/修改表单
  updateForm(params) {
    return gohttp.gopost( '/form/form_main_update', params )
  },
  // 创建副本
  copyForm(params){
    return gohttp.gopost( '/form/form_main_copy', params )
  },

  // 查询我填写的表单
  queryWriteForm(params){
    return gohttp.gopost( '/form/form_write_userme', params )
  },

  // 查询表单设计
  queryFormFilds(params) {
    return gohttp.gopost( '/form/form_fildsdetails_sel', params )
  },
  // 添加、修改表单设计
  updateFormFilds(params) {
    return gohttp.gopost( '/form/form_fildsdetails_update', params )
  },

  // 查询表单填写人数据
  queryFormWriter(params) {
    return gohttp.gopost( '/form/form_write_sel', params )
  },
  // 添加表单填写人
  updateFormWriter(params) {
    return gohttp.gopost( '/form/form_write_update', params, false, true )
  },
  // 删除表单填写人
  deleteFormWriter(params) {
    return gohttp.gopost( '/form/form_write_del', params )
  },

  // 查询表单填写详情
  queryFormDetail(params) {
    return gohttp.gopost( '/form/form_main_write_fildsdetails_sel', params )
  },
  // 添加表单填写详情
  updateFormDetail(params) {
    return gohttp.gopost( '/form/form_main_write_fildsdetails_update', params )
  },
  // 删除表单填写详情
  deleteFormDetail(params) {
    return gohttp.gopost( '/form/form_main_write_fildsdetails_del', params )
  },

  // 填写详情统计
  queryFormDetailStatistics(params) {
    return gohttp.gopost( '/form/form_main_write_pool', params )
  },

  // 添加/修改表单模板
  updateFormTemplate(params) {
    return gohttp.gopost( '/form/form_main_temp_update', params )
  },
  // 删除表单模板
  // deleteFormTemplate(params) {
  //   return gohttp.gopost( '/form/form_main_temp_update', params )
  // },
  // 查询表单模板
  queryFormTemplate(params) {
    return gohttp.gopost( '/form/form_main_temp_sel', params )
  },
  // 新增消息
  sendMessage(params) {
    return gohttp.gopost( '/form/form_news_update', params )
  },
  // 删除消息
  deleteMessage(params) {
    return gohttp.gopost( '/form/form_news_del', params )
  },
  // 查询消息
  queryMessage(params) {
    return gohttp.gopost( '/form/form_news_sel', params )
  },

  // 上传文件
  uploadFiles(params) {
    return gohttp.goUpload( '/form/files', params )
  },
  // 导出数据
  exportResults(params){
    return gohttp.gopost( '/form/form_main_write_pool_excel', params )
  },
//  oss登录
  LoginOss(params){
      return gohttp.goget("/form/login",params)
  },
// 查询oauth地址
  OauthSsoUrl(params){
    return gohttp.goget("/form/ssourl",params)
  },
  OauthRun(params){
    return gohttp.goget("/form/oauth_run",params)
  },
  // 刷新token
  // refreshToken(params){
  //   return gohttp.gopost1("/form/refresh_token",params)
  // },
  // 登录
  tempLogin(params){
    return gohttp.gopost("/form/temp/login", params, false, true)
  },
  // 修改密码
  tempEditPwd(params){
    return gohttp.goput("/form/temp/login", params)
  },
  s3Down(params){
    return gohttp.gopost("/form/form_down", params)
  },
  s3SystemUpload(params){
    return gohttp.gopost("/form/form_system_upload", params)
  },
  s3UserUpload(params){
    return gohttp.gopost("/form/form_user_upload", params)
  },
  s3Put(params,url){
    const config = {
    };
    return gohttp.goput(url,params,config)
  },
  queryProjects(params){
    return gohttp.goget("/form/task/projects", params)
  }
}
