<template>
  <div class="search-bar">
    <el-input
      class="search-input"
      :placeholder="$t('lang.SearchForm')"
      v-model="search"
      @input="querySearchList('search')"
      clearable>
      <i slot="prefix" class="el-input__icon el-icon-search"></i>
    </el-input>
    <ul class="search-result-list" 
      v-if="search != '' && !nodata"
      v-infinite-scroll="querySearchList" 
      :infinite-scroll-disabled="infiniteScroll"
      style="overflow:auto">
      <li v-for="item in searchList" :key="item.id" class="list-item" @click="goDetail(item)">
        <span>{{ item.name }}</span>
        <div>
          <span class="time">{{ internationFormat(item.UpdatedAt) }}{{$t('lang.Index_Svae')}}</span>
        </div>
      </li>
    </ul>
    <ul class="search-result-list" 
      v-else-if="search != '' && nodata">
      <li class="nodata">{{$t('lang.SearchNo')}}</li>
    </ul>
  </div>
</template>

<script>
import api from '@/api/api'

export default {
  name: 'SearchBar',
  data(){
    return {
      currentPage: 0,
      search: '',
      searchList: [],
      infiniteScroll: false,
      nodata: false,
    }
  },
  methods: {
    querySearchList(type){
      if(type == 'search'){
        this.currentPage = 0
      }
      if(this.search != ''){
        this.currentPage++
        api.queryFormNoLoading({
          limit: 10,
          page: this.currentPage,
          name: this.search,
          order: 'id desc',
          // id: localStorage.userId,
          // corp_id: +localStorage.corpId,
        }).then(res => {
          this.nodata = res.count == 0
          this.infiniteScroll = res.data.list.length < 10
          if(this.currentPage == 1){
            this.searchList = []
          }
          this.searchList = this.searchList.concat(res.data.list)
        })
      }
    },
    goDetail(item){
      this.$emit('goDetail', item)
    }
  }
}
</script>

<style scoped>
  .search-bar{
    position: relative;
    width: 50%;
    min-width: 200px;
    max-width: 500px;
	}
	.search-bar .el-input__icon{
		color: #1989fa;
	}
  .search-result-list{
    position: absolute;
    background-color: #fff;
    width: 350px;
    max-height: 340px;
    overflow-y: auto;
    -webkit-box-shadow: 0 2px 12px 0 rgb(98 107 132 / 20%);
    box-shadow: 0 2px 12px 0 rgb(98 107 132 / 20%);
    padding: 8px;
    font-size: 14px;
  }
  .search-result-list .list-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    cursor: pointer;
  }
  .search-result-list .list-item:hover{
    background: #fafafa;
  }
  .time{
    font-size: 12px;
    color: #aeb5c0;
  }
</style>