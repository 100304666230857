<template>
  <el-main class="main">
    <div class="scroll-container">
      <SingleAnswer class="main-content" :id="answerId" :formTitle="title" :name="name" :time="time"/>
    </div>
  </el-main>
  
</template>

<script>
import SingleAnswer from './components/SingleAnswer'
export default {
  name: 'AnswerDetail',
  components: { SingleAnswer },
  data(){
    return {
      answerId: '',
      title: '',
      name: '',
      time: '',
    }
  },
  created(){
    this.answerId = this.$route.query.id
    this.title = this.$route.query.title
    this.name = this.$route.query.name
    this.time = this.$route.query.time
  }
}
</script>

<style scoped>
  .main{
    background: #f2f4f7;
    min-height: 100%;
    display: flex;
    justify-content: center;
  }
  .scroll-container{
    min-width: 900px;
  }
  .main-content{
    width: 900px;
    background: #fff;
    border: 1px solid #e7e9eb;
    position: relative;
    padding: 20px;
  }
</style>