import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import util from './util'
import directives from './directives'
import './common/mixin'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant'
import 'vant/lib/index.css'

Vue.use(directives);
Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Vant);
import VueI18n from 'vue-i18n'
import ClipboardJS from "clipboard"
import vConsole from 'vconsole'

import zhLocale from 'element-ui/lib/locale/lang/zh-TW'
import enLocale from 'element-ui/lib/locale/lang/en'
import jaLocale from 'element-ui/lib/locale/lang/ja'
import ElementLocale from 'element-ui/lib/locale'
// import {i18n, vantLocales} from './common/lang'
import zhTW from 'vant/es/locale/lang/zh-TW'
import enUS from 'vant/es/locale/lang/en-US'
import jaJP from 'vant/es/locale/lang/ja-JP'
import {Locale} from 'vant'

// import './common/felo-sdk-1.4.0.min.js'
let echarts = require('echarts/lib/echarts')
require('echarts/lib/chart/bar')
require('echarts/lib/chart/pie')
import { GridComponent, TooltipComponent } from 'echarts/components';
echarts.use([GridComponent])
echarts.use([TooltipComponent])
Vue.prototype.$echarts = echarts


Vue.use(VueI18n) ;
const i18n = new VueI18n({
  locale: 'en-US',    // 语言标识, 通过切换locale的值来实现语言切换,this.$i18n.locale
  messages: {
    'zh-CN': { lang: require('./common/lang/zh-hant.json'), ...zhLocale, ...zhTW },   // 繁体中文语言包
    'en-US': {lang: require('./common/lang/en.json'), ...enLocale, ...enUS},   // 英文语言包
    'jap': {lang: require('./common/lang/jap.json'), ...jaLocale, ...jaJP},    // 日文语言包
  }
})
ElementLocale.i18n((key, value) => i18n.t(key, value))
vantLocales(i18n.locale)
function vantLocales (lang) {
  if (lang === 'zh-CN') {
    Locale.use(lang, zhTW)
  } else if (lang === 'en-US') {
    Locale.use(lang, enUS)
  } else if (lang === 'jap') {
    Locale.use(lang, jaJP)
  }
}
const vm = new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

const isMobile = util.isMobile()
store.state.isMobile = isMobile
// console.log(store.state.isMobile)
// if(isMobile){
//   const Vconsole = new vConsole()
// }

const clipboard = new ClipboardJS('.clipboard')
clipboard.on('success', function(e) {
  if(isMobile){
    vm.$toast(vm.$t('lang.copyLink'))
  }else{
    vm.$message.success(vm.$t('lang.copyLink'))
  } 
});
