<template>
  <div>
    <div>
      <button @click="down()">下载</button>
      <el-form label="证件照片" prop="idImage">
        <input @change='ss'  type="file">
      </el-form>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";
import {Message} from "element-ui";
export default {
  name: "Ceshi",
  created() {

  },
  data() {
    return {
      ruleForm:{},
    }
  },
  methods:{
    down(){
      api.s3Down({
        name:"54.docx"
      }).then(res => {
        console.log(res)
      })
    },
    ss(event){
      var reader =new FileReader();
      var img1=event.target.files[0];
      reader.readAsDataURL(img1);
      var that=this;
      reader.onload=function(e){
        const param = new FormData();
        param.append("file", reader.result);
        //代码
        //找后端接口要s3访问地址
        api.s3UserUpload({
          //加下唯一标识防止冲突
          name:img1.name
        }).then(res => {

              api.s3Put(img1,res.data.URL).then(ret=>{
                console.log(ret,"sds")
              })
        })
      }
      // console.log();
    },
  }
}
</script>

<style scoped>

</style>
