<template>
  <div>
    <Header>
      <div slot="left" id="header-left">
				<div class="title">
					<img class="imgdoc" src="../assets/images/form-icon.png" />
        	{{$t('lang.Header_title')}}
				</div>
      </div>
    </Header>
    <el-container class="container">
      <el-aside class="side">
        <el-button type="primary" icon="el-icon-plus" class="button" @click="openNewFormDialog">{{$t('lang.Index_created')}}</el-button>
        <div class="fenlei bgcolor"><i class="el-icon-s-order"></i>模板管理</div>
      </el-aside>
      <div class="main-content">
        <div class="list-header">
          <div class="left">模板管理</div>
					<!-- <span class="filter" :class="{'on': formListStar}"
						@click="formListStar = !formListStar">
						<i class="el-icon-star-off"></i>{{$t('lang.Index_star')}}
					</span> -->
          <div>
            <span class="select-item">
              <label>语言：</label>
              <el-select v-model="lang" placeholder="请选择">
                <el-option
                  v-for="item in langOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </span>
            <span class="select-item">
              <label>状态：</label>
              <el-select v-model="status" placeholder="请选择">
                <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </span>
          </div>
        </div>
        <div v-if="templateList.length > 0">
          <div class="contone" v-for="item in templateList" :key="item.id">
            <el-row :gutter="20" class="contonediv">
              <el-col :span="7">
              <div class="contdiv" @click="goDetail(item)">
                <span class="conttitle">{{item.name}}</span>
              </div>
              </el-col>
              <el-col :span="9"><div class="grid-content">
                <el-tag v-if="item.temp_sta" size="mini">已上架</el-tag>
                <el-tag v-else size="mini" type="danger">未上架</el-tag>
                <span class="timetitle">{{ internationFormat(item.UpdatedAt) }} {{$t('lang.Index_Svae')}}</span>
              </div></el-col>
              <el-col :span="8"><div class="grid-content more">
                <i v-if="item.star == 1" class="el-icon-star-on star" @click="updateStar(item, 2)"></i>
                <i v-else class="el-icon-star-off hover-dom star" @click="updateStar(item, 1)"></i>
                <div v-if="item.star == 2">
                  <el-button size="small" type="info" plain v-if="item.temp_sta" @click="updateStatus(item, false)">下架</el-button>
                  <el-button size="small" type="success" plain v-else @click="updateStatus(item, true)">上架</el-button>
                  <el-button size="small" type="danger" plain @click="deleteForm(item)">删除</el-button>
                </div>
              </div></el-col>
            </el-row>
          </div>
          <el-pagination
            class="pagination"
            layout="prev, pager, next"
            :current-page.sync="currentPage"
            :total="tempListTotal"
            @current-change="queryTempleteList">
          </el-pagination>
        </div>
        <el-empty v-else :description="$t('lang.NoData')"></el-empty>
      </div>
    </el-container>
    <el-dialog
      :visible.sync="newFormDialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeNewFormDialog"
      width="450px" class="create-dialog">
      <p>{{$t('lang.formCreateform')}}</p>
      <div class="input-div">
        <el-input class="title-input" v-model="newFormTitle" :placeholder="$t('lang.formWriteTitle')"></el-input>
        <el-button type="primary" @click="createForm">{{$t('lang.formCreatedSub')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import api from '@/api/api'
import store from '@/store'
import Header from '@/components/Header'
import { Message } from 'element-ui'

export default {
  name: 'TemplateMgmt',
  components: {
		Header,
	},
  data(){
    return {
      newFormDialogVisible: false,
      newFormTitle: '',
      templateList: [],
      currentPage: 1,
      tempListTotal: 0,
      lang: 1,
      status: '',
      langOptions: [
        {label: '日语', value: 1},
        {label: '英文', value: 2},
        {label: '中文繁体', value: 3},
      ],
      statusOptions: [
        {label: '全部', value: ''},
        {label: '上架', value: 1},
        {label: '未上架', value: 2},
      ],
      soso: '',
      starCount: '',
    }
  },
  watch: {
    lang(val){
      sessionStorage.setItem('tempLang', val)
      this.setSoso()
    },
    status(){
      this.setSoso()
    },
  },
  created(){
    if(!sessionStorage.getItem('loginFlag')){
      this.$router.push('/login')
    }
    this.lang = +sessionStorage.getItem('tempLang') || 1
    this.setSoso()
  },
  methods: {
    openNewFormDialog(){
      this.newFormDialogVisible = true
    },
    closeNewFormDialog(){
      this.newFormDialogVisible = false
      this.newFormTitle = ''
    },
    createForm(){
      api.updateForm({
        name: this.newFormTitle,
        is_temp: 1,
        temp_lang: this.lang,
        temp_sta: false,
      }).then(res => {
        if(res.data.is_ok == 1){
          sessionStorage.setItem('createFlag', 'createTemplate')
          this.$router.push({
            path: '/newForm',
            query: {
              title: this.newFormTitle,
              formId: res.data.form_main_dbid,
            }
          })
        }  
      })
    },
    queryTempleteList(){
			api.queryForm({
				limit: 10,
				page: this.currentPage,
				order: 'updated_at desc',
        soso: this.soso,
        is_temp: 1,
			}).then(res => {
				this.templateList = res.data.list
        this.starCount = res.data.star_count
				this.tempListTotal = res.count
			})
		},
    setSoso(){
      this.currentPage = 1
      this.soso = ''
      if(this.lang){
        this.soso = `temp_lang=${this.lang}`
      }
      if(this.status){
        let sta = this.status == 1 ? true : false
        this.soso = this.soso ? `${this.soso} AND temp_sta=${sta}` : this.soso
      }
      this.queryTempleteList()
    },
    updateStar(item, star){
      if(this.starCount >= 4 && star == 1){
        Message.warning('最多4个热门推荐模板，可取消其他模板，再设置此模板为热门推荐模板')
      }else if(this.starCount == 1 && star == 2){
        Message.warning('至少包含一个热门推荐模板')
      }else{
        api.updateForm1({
          id: item.id,
          star,
        }).then(res => {
          if(res.data.is_ok == 1){
            this.currentPage = 1
            this.queryTempleteList()
          }  
        }).catch(err => {
          switch (err.code){
            case 6001:
              Message.error('最多4个热门推荐模板，可取消其他模板，再设置此模板为热门推荐模板')
              break
            case -6002:
              Message.error('至少包含一个热门推荐模板')
              break
            case -6003: 
              Message.error('未上架的模板不可设置星标')
              break
            default:
              Message.error(err.msg)
              break
          }
        })
      }
		},
    updateStatus(item, temp_sta){
      api.updateForm({
        id: item.id,
        temp_sta,
      }).then(res => {
        if(temp_sta){
          Message.success('上架成功')
        }else{
          Message.success('下架成功')
        }
        this.queryTempleteList()
      })
    },
    goDetail(item){
      sessionStorage.setItem('createFlag', 'createTemplate')
      this.$router.push({
				path: '/newForm',
				query: {
					formId: item.id,
					editFlag: true,
				}
			})
    },
    deleteForm(item){
      this.$confirm('确定要删除该模板吗？', this.$t('lang.Tips'), {
        type: 'warning'
      }).then(() => {
        api.deleteForm({
          id: item.id,
        }).then(res => {
          Message.success(this.$t('lang.MsgDelSuccess'))
          this.queryTempleteList()
        })
      })
		},
  },
}
</script>

<style scoped>
  .container{
		margin-top: 60px;
		height: calc(100vh - 60px);
		min-width: 890px;
	}
  .side{
		width: 200px !important;
		height: 100%;
		border-right:1px solid #e7e9eb;
		padding: 20px;
	}
	.button{
		width: 100%;
		height: 37px;
		border-radius: 0px !important;
		color: white;
		font-weight: 700;
	}
	.fenlei{
		/* width: 180px; */
		height: 37px;
		margin: 0 auto;
		line-height: 37px;
		font-size: 14px;
		color: #6c748a;
		text-align: left;
		margin-bottom: 10px;
		cursor: pointer;
    margin-top: 60px;
	}
	.fenlei i{
		margin-right: 10px;
		margin-left: 10px;
		color: #a5b5ce;
	}
	.bgcolor{
		background: #f2f5fa;
	}
  .main-content{
    flex: 1;
    padding: 20px 0 20px 20px;
  }
  .list-header{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-bottom: 20px;
		height: 40px !important;
		border-bottom: 1px solid #e7e9eb;
	}
  .contonediv{
		margin: 0 !important;
		border-bottom: 1px solid #e7e9eb;
		height: 100%;
    display: flex;
    align-items: center;
	}
	.contonediv .more{
		display: flex;
		align-items: center;
		justify-content: flex-end;
	}
	.more .star{
		font-size: 16px;
		margin: 0 15px;
		cursor: pointer;
	}
	.more .el-icon-star-off:hover{
		color: #1989fa;
	}
	.more .el-icon-star-on{
		color: #fcdf39;
		font-size: 20px;
	}
	.more .hover-dom{
		display: none;
	}
	.contonediv:hover .hover-dom{
		display: inline-block;
	}
	.contone{
		line-height: 66px;
		height: 66px;
		clear: both;
	}
	.conttitle{
		color: #3d4757;
		font-size: 14px;
		margin-left: 10px;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
  .contdiv{
		text-align: left;
		cursor: pointer;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.timetitle{
		color: #767c85;
		font-size: 13px;
		margin-left: 10px;
	}
	.contone:hover .contonediv{
		background: #F2F5FA;
	}
  .grid-content.more{
		padding-right: 30px;
	}
  .more-icon{
		display: inline !important;
		cursor: pointer;
	}
  .list-header .select-item{
    font-size: 14px;
    margin-right: 20px;
  }
  .select-item label{
    margin-right: 10px;
  }
  .create-dialog p{
    font-weight: 700;
    font-size: 16px;
  }
  .create-dialog .input-div{
    display: flex;
    padding: 30px 0;
  }
  .create-dialog .title-input{
    margin-right: 10px;
  }
  .pagination{
		margin-top: 40px;
	}
</style>