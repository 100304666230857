import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMobile: false,
    picBaseUrl: `${location.origin}/`,
    refreshFlag: false,
    mobileQuestionTypeList:[
      {
        text: '选择题',
        children: [
          {type: 'radio', text:'单选题', id:'radio'},
          {type: 'select', text:'多选题', id:'select'},
        ]
      },
      {
        text: '填空题',
        children: [
          {type: 'text', text:'单行文本', id:'text'},
          {type: 'textarea', text:'多行文本', id:'textarea'},
        ]
      },
      {
        text: '附件题',
        children: [
          {type: 'input', text:'上传单文件', id:'input'},
          {type: 'inputs', text:'上传多文件', id:'inputs'},
        ]
      },
      {
        text: '图片题',
        children: [
          {type: 'images', text:'上传多图片', id:'images'},
        ]
      },
      {
        text: '评分题',
        children: [
          {type: 'score', text: '评分', id: 'score'},
        ]
      }
    ],
    toPage: '', // 记录跳转页面
    // feloDownloadUrl: 'https://felo.me/joinchannel/9e44e61579cfeb50/$token$#/chatter',
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
