<template>
  <div id="mobile-index" :class="searchingStatus? 'noscroll': ''">
    <SearchBar @searching="searching" @goDetail="goDetail"/>
    <van-tabs color="#409eff" animated sticky v-model="activeName" @change="onRefresh">
      <van-tab :title="$t('lang.Index_meCreated')" name="create">
        <div class="list-filter">
          <span @click="formListStar = !formListStar">
            <van-icon v-if="formListStar" class="star" name="star" />
            <van-icon v-else class="" name="star-o" />
            {{ $t('lang.Index_star') }}
          </span>
        </div>
        <van-pull-refresh 
          v-model="refreshing.create"
          :pulling-text="$t('lang.pullingText')" 
          :loosing-text="$t('lang.loosingText')" 
          :loading-text="$t('lang.loadingText')" 
          @refresh="onRefresh">
          <van-list
            v-model="loading.create"
            :finished="finished.create"
            offset="0"
            @load="queryFormList"
            v-if="myFormList.length > 0"
          >
            <div class="list-item" v-for="item in myFormList" :key="item.id">
              <div @click="goDetail(item)">
                <div class="title">
                  <span>{{item.name}}</span>
                  <van-icon v-if="item.star == 1" class="star" name="star" />
                </div>
                <div class="detail">
                  <div>
                    <span class="date" v-if="!item.is_push && item.start_sta == 2">
                      {{ internationFormat(item.start_time) }}{{$t('lang.Index_SubmitRun')}}
                    </span>
                    <span class="date" v-else-if="item.is_push && item.off_sta == 1">
                      {{ internationFormat(item.off_time) }}{{$t('lang.Index_SubmitStop')}}
                    </span>
                    <span class="date" v-else>{{ internationFormat(item.UpdatedAt) }}{{$t('lang.Index_Svae')}}</span>
                  </div>
                </div>
              </div>
              <div class="oprate space-between">
                <div>
                  <van-tag type="danger" size="medium" round plain v-if="item.sta == 2">{{$t('lang.Index_collect_stop')}}{{item.count}}{{$t('lang.Index_collect_n')}}</van-tag>
                  <van-tag type="primary" size="medium" round plain v-else-if="item.is_push">{{$t('lang.Index_collect')}}{{item.count}}{{$t('lang.Index_collect_n')}}</van-tag>
                  <van-tag type="warning" size="medium" round plain v-else>{{$t('lang.Index_draft')}}</van-tag>
                </div>
                <div>
                  <van-button size="small" type="default" v-if="item.is_push && item.sta == 1" @click="invite(item)">{{$t('lang.Index_Invite')}}</van-button>
                  <van-button size="small" type="default" @click="goDetail(item)" v-if="!item.is_push">{{$t('lang.Index_ContinueEditing')}}</van-button>
                  <van-icon class="more" name="ellipsis" @click="clickMore(item)"/>
                </div>
              </div>
            </div>
          </van-list>
          <van-empty v-else :description="$t('lang.NoData')" />
          <van-divider v-if="finished.create && myFormList.length > 0">{{$t('lang.formNodeDtta')}}</van-divider>
        </van-pull-refresh>
      </van-tab>
      <van-tab :title="$t('lang.Index_meWrite')" name="write" class="tab-container">
        <van-pull-refresh 
          v-model="refreshing.write"
          :pulling-text="$t('lang.pullingText')" 
          :loosing-text="$t('lang.loosingText')" 
          :loading-text="$t('lang.loadingText')"  
          @refresh="onRefresh">
          <van-list
            v-model="loading.write"
            :finished="finished.write"
            offset="0"
            @load="queryWriteList"
            v-if="myWriteFormList.length > 0"
          >
            <div class="list-item" v-for="item in myWriteFormList" :key="item.id">
              <div @click="goPreview(item)">
                <div class="title">{{item.form_main_name}}</div>
                <div class="detail">
                  <div>
                    <van-tag round plain size="medium">{{ $t('lang.Index_Form') }}</van-tag>
                  </div>
                  <van-tag type="primary" size="medium" round plain>{{ $t('lang.Index_Submitted') }}</van-tag>
                </div>
              </div>
              <div class="oprate">
                <van-icon class="more" name="ellipsis" @click="clickMore(item)"/>
              </div>
            </div>
          </van-list>
          <van-empty v-else :description="$t('lang.NoData')" />
          <van-divider v-if="finished.write && myWriteFormList.length > 0">{{$t('lang.formNodeDtta')}}</van-divider>
        </van-pull-refresh>
      
      </van-tab>
    </van-tabs>
    
    <!-- 我创建的 菜单 -->
    <van-action-sheet 
      v-model="formActionShow"   
      :cancel-text="$t('lang.ButtonCall')"
      close-on-click-action>
        <div class="action-item" v-if="chooseItem.is_push && chooseItem.sta == 1" @click="updateCollectStatus(2)">{{$t('lang.Index_Stop')}}</div>
        <div class="action-item" v-if="chooseItem.sta == 2" @click="updateCollectStatus(1)">{{$t('lang.Index_continue')}}</div>
        <div class="action-item" v-if="chooseItem.is_push && chooseItem.sta == 1" @click="writeForm">{{$t('lang.Index_Fill')}}</div>
        <div class="action-item" v-if="chooseItem.star == 2" @click="updateStar(1)">{{$t('lang.Index_SetStar')}}</div>
        <div class="action-item" v-if="chooseItem.star == 1" @click="updateStar(2)">{{$t('lang.Index_CancelStar')}}</div>
        <div class="action-item" @click="copyForm">{{$t('lang.Index_Copy')}}</div>
        <div class="action-item" @click="openSetting">{{$t('lang.Index_Setting')}}</div>
        <div class="action-item" @click="deleteForm">{{$t('lang.Index_Del')}}</div>
    </van-action-sheet>

    <!-- 我填写的 菜单 -->
    <van-action-sheet 
      v-model="writeActionShow"   
      :cancel-text="$t('lang.ButtonCall')"
      close-on-click-action>
        <div class="action-item" @click="deleteWriteForm">{{$t('lang.Index_Del')}}</div>
    </van-action-sheet>

    <!-- 设置 -->
    <Setting
      class="setting"
      ref="settings"
      v-show="settingVisible"
      :editFlag="true"
      :formSettings="formSettings"
      @confirm="settingConfirm"
    >
      <div class="footer" slot="footer">
        <van-button class="btn" @click="settingMaskCancel">{{$t('lang.ButtonCall')}}</van-button>
        <van-button class="btn" type="info" @click="settingMaskConfirm">{{$t('lang.ButtonSub')}}</van-button>
      </div>
    </Setting>

    <!-- 邀请填写 -->
    <van-action-sheet
      v-model="shareShow"
      :title="$t('lang.Index_Invite')">
    <div class="more-action">
      <span class="item">
        <i class="el-icon-link clipboard" :data-clipboard-text="chooseItem.shareUrl" @click="shareClose"></i>
        <span>{{$t('lang.newFormSubIconLink')}}</span>
      </span>
      <span class="item">
        <van-icon name="qr" @click="openQrcode" />
        <span>{{$t('lang.newFormSubQr')}}</span>
      </span>
      <span class="item">
        <van-icon name="description" class="clipboard" :data-clipboard-text="chooseItem.shareUrl" @click="shareClose"/>
        <span>felo</span>
      </span>
    </div>
    </van-action-sheet>

    <!-- 二维码 -->
    <Qrcode
      v-if="qrcodeVisible"
      :formTitle="chooseItem.name"
      :shareUrl="chooseItem.shareUrl"
      @closeQrcode="qrcodeVisible = false" />

    <!-- 新建表单弹窗 -->
    <CreateFormMask v-if="createMask" @closeCreateFormMask="closeCreateFormMask" />

    <!-- 创建按钮 -->
    <i class="create-icon el-icon-circle-plus" @click="createNewForm"></i>
  </div>
</template>

<script>
import api from '@/api/api'
import store from '@/store'
import SearchBar from '@/components/mobile/SearchBar'
import Setting from '@/components/mobile/Setting'
import Qrcode from '@/components/mobile/Qrcode'
import CreateFormMask from '@/components/mobile/CreateFormMask'
import { Toast } from 'vant'

const moment = require('moment')

export default {
  name: 'Index',
  components: {
    SearchBar,
    Setting,
    Qrcode,
    CreateFormMask,
  },
  data(){
    return {
      userId: '',
      corpId: '',
      activeName: 'create',
      refreshing: {
        create: false,
        write: false,
      },
      loading: {
        create: false,
        write: false,
      },
      finished: {
        create: false,
        write: false,
      },
      formListStar: false,
      myFormList: [],
      myWriteFormList: [],
      formCurrentPage: 0,
      writeFormCurrentPage: 0,
      formTotal: 0,
      writeFormTotal: 0,
      chooseItem: {},
      formActionShow: false,
      writeActionShow: false,
      createMask: false,
      newFormTitle: '',
      formSettings: {},
      settingVisible: false,
      searchingStatus: false,
      shareShow: false,
      qrcodeVisible: false,
    }
  },
  watch: {
    formListStar(){
			this.onRefresh()
		},
    '$i18n.locale'(){
      this.createShareUrl()
    }
  },
  activated(){
    this.formActionShow = false
    if( store.state.refreshFlag == 'NewForm'){
			this.activeName = 'create'
      this.onRefresh()
		}else if( store.state.refreshFlag == 'WriteForm'){
			this.activeName = 'write'
      this.onRefresh()
		}
  },
  created(){
    this.onRefresh()
  },
  methods:{
    createNewForm(){
			this.$router.push('/template')
		},
    createShareUrl(){
			this.myFormList.forEach(item => {
				if(item.is_push){
					item.shareUrl = `${location.origin}/writeForm?formId=${item.id}`
				}
			})
		},
    queryFormList(){
			api.queryForm({
				limit: 10,
				page: ++this.formCurrentPage,
				order: 'updated_at desc',
        star: this.formListStar ? 1 : '',
				// id: this.userId,
        // corp_id: this.corpId,
			}).then(res => {
				this.formTotal = res.count
        if(this.formTotal == 0 || this.formCurrentPage == 1){
          this.myFormList = []
        }
        this.myFormList = this.myFormList.concat(res.data.list)
        this.loading.create = false
        this.refreshing.create = false
        if(this.myFormList.length >= this.formTotal){
          this.finished.create = true
        }
        this.createShareUrl()
        store.state.refreshFlag == ''
			})
		},
    queryWriteList(){
			api.queryWriteForm({
				limit: 10,
				page: ++this.writeFormCurrentPage,
				order: 'id desc',
				// id: this.userId,
        // corp_id: this.corpId,
			}).then(res => {
				this.writeFormTotal = res.count
        if(this.writeFormTotal == 0 || this.writeFormCurrentPage == 1){
          this.myWriteFormList = []
        }
        this.myWriteFormList = this.myWriteFormList.concat(res.data)
        this.loading.write = false
        this.refreshing.write = false
        if(this.myWriteFormList.length >= this.writeFormTotal){
          this.finished.write = true
        }
        store.state.refreshFlag == ''
			})
		},
    searching(status){
      this.searchingStatus = status
    },
    onRefresh(){
      if(this.activeName == 'create'){
        this.formCurrentPage = 0
        this.loading.create = true
        this.queryFormList()
      }else if(this.activeName == 'write'){
        this.writeFormCurrentPage = 0
        this.loading.write = true
        this.queryWriteList()
      }
    },
    clickMore(item){
      this.chooseItem = item
      if(this.activeName == 'create'){
        this.formActionShow = true
      }else if(this.activeName == 'write'){
        this.writeActionShow = true
      }
    },
    closeFormActionSheet(){
      this.formActionShow = false
    },
    writeForm(){
      this.closeFormActionSheet()
      this.$router.push({
        path: '/writeForm',
        query: {
          formId: this.chooseItem.id
        }
      })
    },
    updateCollectStatus(sta){
      this.closeFormActionSheet()
			api.updateForm({
        id: this.chooseItem.id,
				sta,
      }).then(res => {
				if(sta == 1){
					Toast(this.$t('lang.Index_OpenSta'))
				}else{
					Toast(this.$t('lang.Index_CloseSta'))
				}
        this.onRefresh()
      })
		},
    updateStar(star){
      this.closeFormActionSheet()
			api.updateForm({
        id: this.chooseItem.id,
				star,
      }).then(res => {
        if(res.data.is_ok == 1){
          this.chooseItem.star = star
        }  
      })
		},
    openSetting(){
      this.closeFormActionSheet()
			api.queryOneForm({
        id: this.chooseItem.id,
      }).then(res => {
        let data = res.data
        this.formSettings = {
					start_sta: data.start_sta,
          start_time: data.start_time,
          off_sta: data.off_sta,
          off_time: data.off_time,
          login_run: data.login_run,
          day_one: data.day_one,
          user_one: data.user_one,
          sub_count: data.sub_count,
          submitNum: data.sub_count == 0 ? 10 : data.sub_count,
          news_send: data.news_send,
          sta: data.sta,
					is_push: data.is_push,
        }
				this.settingVisible = true
      })
		},
    copyForm(){
      this.closeFormActionSheet()
			api.copyForm({
				id: this.chooseItem.id
			}).then(res => {
				this.onRefresh()
			})
		},
    deleteForm(){
      this.closeFormActionSheet()
			api.deleteForm({
				id: this.chooseItem.id,
			}).then(res => {
				Toast(this.$t('lang.MsgDelSuccess'))
        this.onRefresh()
			})
		},
		deleteWriteForm(){
      this.writeActionShow = false
			api.deleteFormWriter({
				id: this.chooseItem.id,
			}).then(res => {
				Toast(this.$t('lang.MsgDelSuccess'))
        this.onRefresh()
			})
		},
    settingMaskCancel(){
      this.settingVisible = false
    },
    settingMaskConfirm(){
      this.$refs.settings.confirm()
    },
    settingConfirm(settings){
      // console.log(settings)
      api.updateForm({
        id: this.chooseItem.id,
        start_sta: settings.start_sta,
        start_time: settings.start_time,
        off_sta: settings.off_sta,
        off_time: settings.off_time,
        login_run: settings.login_run,
        day_one: settings.day_one,
        // run_edit: settings.run_edit,
        user_one: settings.user_one,
        sub_count: settings.sub_count? parseInt(settings.submitNum) : 0,
      }).then(res => {
        if(res.data.is_ok == 1){
          this.settingMaskCancel()
          Toast(this.$t('lang.MsgSetSuccess'))
          this.onRefresh()
        }  
      })
    },
    invite(item){
      this.chooseItem = item
      this.shareShow = true
    },
    shareClose(){
      this.shareShow = false
    },
    openQrcode(){
      this.shareClose()
      this.qrcodeVisible = true
    },
    closeCreateFormMask(){
      this.createMask = false
    },
    goDetail(item){
			if(item.is_push){
				this.$router.push({
					path: '/collectDetail',
					query: {
						formId: item.id,
					}
				})
			}else{
				this.$router.push({
					path: '/newForm',
					query: {
						formId: item.id,
						editFlag: true,
					}
				})
			}
		},
		goPreview(item){
			this.$router.push({
				path: '/writeForm',
				query: {
					formId: item.form_main_db_id,
					writeId: item.id,
					submitDate: this.internationFormat(item.UpdatedAt),
					submit: item.sta == 2 ? 'submit':'',
				}
			})
		},
  }
}
</script>

<style scoped>
  #mobile-index{
    position: relative;
  }
  #mobile-index.noscroll{
    overflow-y: hidden;
  }
  header{
    background: #fff;
    border-bottom: 1px solid #eee;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .create-icon{
    color: #409EFF;
    font-size: 50px;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 99;
  }
  .list-filter{
    text-align: left;
    font-size: 14px;
    padding: 15px 20px 0;
  }
  .list-filter span{
    display: flex;
    align-items: center;
  }
  .list-filter .van-icon{
    margin-right: 5px;
  }
  .list-filter .star{
    color: #FBD61D;
  }
  .list-item{
    margin: 15px;
    border-radius: 8px;
    background: #fff;
    padding: 10px;
    text-align: left;
  }
  .list-item .title{
    font-size: 15px;
    padding: 10px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .list-item .title .star{
    font-size: 17px;
    color: #FBD61D;
  }
  .list-item .detail{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    padding-bottom: 15px;
  }
  .list-item .oprate{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 10px 0 5px;
    border-top: 1px solid #eee;
  }
  .list-item .oprate.space-between{
    justify-content: space-between;
  }
  .list-item .oprate > div{
    display: flex;
    align-items: center;
  }
  .list-item .detail .date{
    margin-left: 5px;
  }
  .list-item .oprate .more{
    margin-left: 10px;
  }

  .divider{
    margin: 60px 0 30px;
  }
  .more-action{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 20px 0 40px;
  }
  .more-action .item{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
  }
  .more-action .item .van-icon,
  .more-action .item .el-icon-link{
    font-size: 30px;
    color: #409EFF;
    margin-bottom: 10px;
  }
  .action-item{
    padding: 15px 0;
  }
  .setting{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .footer{
    width: 100%;
    height: 70px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 101;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
  }
  .footer .btn{
    width: 45%;
  }
</style>