<template>
  <div id="mobile-copy-form">
    <van-nav-bar
      :title="$t('lang.formIsOne')"
      left-arrow
      @click-left="back"
    />
    <van-list
      v-model="loading"
      :finished="finished"
      offset="0"
      @load="queryFormList"
      v-if="myFormList.length > 0"
    >
      <div class="list-item" v-for="item in myFormList" :key="item.id">
        <div @click="goDetail(item)">
          <div class="title">
            <span>{{item.name}}</span>
          </div>
        </div>
        <div class="oprate">
          <span>{{ internationFormat(item.UpdatedAt) }}{{ $t('lang.Index_Svae') }}</span>
          <van-button size="small" type="default" @click="copy(item)">{{ $t('lang.formCopyFe') }}</van-button>
        </div>
      </div>
    </van-list>
    <van-empty v-else :description="$t('lang.NoData')" />
    <van-divider v-if="finished && myFormList.length > 0">{{ $t('lang.formNodeDtta') }}</van-divider>
  </div>
</template>

<script>
import api from '@/api/api'

export default {
  name: 'CopyForm',
  data(){
    return {
      myFormList: [],
      loading: false,
      finished: false,
      formCurrentPage: 1,
    }
  },
  created(){
    this.queryFormList()
  },
  methods:{
    back(){
      this.$router.back()
    },
    queryFormList(){
      this.loading = true
			api.queryForm({
				limit: 10,
				page: this.formCurrentPage++,
				order: 'updated_at desc',
				// id: localStorage.userId,
        // corp_id: +localStorage.corpId,
			}).then(res => {
        this.myFormList = this.myFormList.concat(res.data.list)
        this.loading = false
        if(this.myFormList.length >= res.count){
          this.finished = true
        }
			})
		},
    copy(item){
      api.copyForm({
				id: item.id
			}).then(res => {
        this.$router.push({
          path: '/newForm',
          query: {
            formId: res.data,
            editFlag: true,
          }
        })
			})
    }
  }
}
</script>

<style scoped>
  .list-item{
    margin: 15px;
    border-radius: 8px;
    background: #fff;
    padding: 10px;
    text-align: left;
  }
  .list-item .title{
    font-size: 15px;
    padding: 10px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .list-item .oprate{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    border-top: 1px solid #eee;
    font-size: 14px;
  }
  .list-item .oprate > span{
    color: #999;
  }
</style>