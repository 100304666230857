<template>
  <el-form :model="settings" class="setting-content">
    <el-form-item v-if="!settings.is_push">
      <label class="item-label select-label">{{$t('lang.newFormSettingRegularRelease')}}</label>
      <el-select v-model="settings.start_sta">
        <el-option :label="$t('lang.newFormSettingNoRestrictions')" :value="1"></el-option>
        <el-option :label="$t('lang.newFormSettingRegularRelease')" :value="2"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item v-if="settings.start_sta == 2 && !settings.is_push">
      <label class="item-label select-label">{{$t('lang.newFormSettingSendTime')}}</label>
      <el-date-picker
        v-model="settings.start_time"
        value-format="yyyy-MM-dd HH:mm:ss"
        type="datetime"
        :placeholder="$t('lang.newFormPlaseDate')">
      </el-date-picker>
    </el-form-item>
    <el-form-item v-if="settings.sta != 2">
      <label class="item-label select-label">{{$t('lang.newFormSettingStopTime')}}</label>
      <el-select v-model="settings.off_sta">
        <el-option :label="$t('lang.newFormSettingNoRestrictions')" :value="0"></el-option>
        <el-option :label="$t('lang.newFormSettingStopTime')" :value="1"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item v-if="settings.off_sta == 1 && settings.sta != 2">
      <label class="item-label select-label">{{$t('lang.newFormSettingTime')}}</label>
      <el-date-picker
        v-model="settings.off_time"
        value-format="yyyy-MM-dd HH:mm:ss"
        type="datetime"
        :placeholder="$t('lang.newFormPlaseDate')">
      </el-date-picker>
    </el-form-item>
    
    <el-form-item>
      <div class="item-label">{{$t('lang.newFormSettingWriteUser')}}</div>
      <p>
        <el-switch v-model="settings.login_run"></el-switch>
        {{$t('lang.newFormSettingIsLogin')}}
      </p>
    </el-form-item>
    <el-form-item>
      <div class="item-label">{{$t('lang.newFormSettingAuthority')}}</div>
      <p>
        <el-switch v-model="settings.day_one"></el-switch>
        {{$t('lang.newFormSettingOneDay')}}
      </p>
      <!-- <p>
        <el-switch v-model="settings.run_edit"></el-switch>
        允许填写者再次修改
        <el-tooltip effect="light" content="开启后，填写表单需要登录，且填写者可修改自己提交过的答案" placement="bottom">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </p> -->
      <p>
        <el-switch v-model="settings.user_one"></el-switch>
        {{$t('lang.newFormSettingOneUser')}}
      </p>
      <p>
        <el-switch v-model="settings.sub_count"></el-switch>
        {{$t('lang.newFormSettingWriteSum')}}
        <span class="submitNumInput" v-show="settings.sub_count">
          <el-input size="mini" v-model="settings.submitNum"></el-input>{{$t('lang.Index_collect_n')}}
        </span>
        <el-tooltip effect="light" :content="$t('lang.newFormSettingWriteSumTitle')" placement="bottom">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </p>
    </el-form-item>
    <el-form-item>
      <div class="item-label">
        {{$t('lang.workFlowSet')}}
        <span class="tip" v-if="form2taskTipShow" @click="closeTip">{{$t('lang.form2taskTip')}}</span>
      </div>
      <p>
        <el-switch v-model="settings.workflow_switch"></el-switch>
        {{$t('lang.workFlowSwitch')}}
      </p>
      <template v-if="settings.workflow_switch">
        <div>
          <p>{{$t('lang.taskTitle')}}</p>
            <vue-tribute :options="tributeOptions">
              <p 
                ref="taskTitle"
                contenteditable 
                class="task-title-input"
                :placeholder="$t('lang.form2taskTitle')"
                @keydown="tributeKeydown"
                @keyup="tributeKeyup">
              </p>
            </vue-tribute>
        </div>
        <div>
          <p>{{$t('lang.taskProject')}}</p>
          <el-select v-model="settings.related_projects" :placeholder="$t('lang.selectPlacholder')">
            <el-option
              v-for="item in projectsList"
              :key="item.id"
              :label="item.title"
              :value="item.id">
            </el-option>
          </el-select>
        </div>
      </template>
      
    </el-form-item>
<!--    <el-form-item>-->
<!--      <div class="item-label">{{$t('lang.newFormSettingWriteSock')}}</div>-->
<!--      <p>-->
<!--        <el-switch v-model="settings.news_send"></el-switch>-->
<!--        {{$t('lang.newFormSettingWriteSockTitle')}}-->
<!--        <el-tooltip effect="light" :content="$t('lang.newFormSettingWriteSockTitle')" placement="bottom">-->
<!--          <i class="el-icon-warning-outline"></i>-->
<!--        </el-tooltip>-->
<!--      </p>-->
<!--    </el-form-item>-->
  </el-form>
</template>

<script>
import api from '@/api/api'
import VueTribute from 'vue-tribute'
const moment = require('moment')

export default {
  name: 'Setting',
  components: { VueTribute },
  data(){
    return{
      settings: {
        start_sta: 1,
        start_time: '',
        off_sta: 0,
        off_time: '',
        login_run: false,
        day_one: false,
        // run_edit: false,
        user_one: false,
        sub_count: false,
        submitNum: 10,
        news_send: false,
        sta: 1,
        is_push: false,
        workflow_switch: false,
        related_projects: '',
        task_info: {},
      },
      form2taskTipShow: false,
      questionList: [],
      projectsList: [],
      tributeOptions: {
        trigger: "/",
        lookup: 'text',
        values: [],
        selectTemplate: function (item) {
          if (typeof item === "undefined") return null;
          if (this.range.isContentEditable(this.current.element)) {
            return (`<span class="title-temp" qid="${item.original.id}" contenteditable="false">"${item.original.value}"</span>`)
          }
          return '"' + item.original.value + '"';
        },
        requireLeadingSpace: false
      }
    }
  },
  props: {
    editFlag:{
      type: Boolean,
      default: false,
    },
    formSettings:{
      type: Object,
      default(){
        return {}
      }
    }
  },
  created(){
    if(this.editFlag){
      this.formatSettings()
    }
    if(!localStorage.getItem('form2taskTip')){
      this.form2taskTipShow = true
    }
  },
  computed: {
    switch(){
      return this.settings.workflow_switch
    },
    noTaskInfo(){
      return this.questionList.length == 0 || this.projectsList.length == 0
    },
  },
  watch:{
    formSettings(newValue, oldValue){
      this.formatSettings()
    },
    async switch(v){
      if(v){ this.closeTip() }
      if(v && this.noTaskInfo){
        await this.queryFormQuestions()
        await this.queryProjects()
      }
    }
  },
  methods: {
    close(){
      this.formatSettings()
      this.$emit('close')
    },
    confirm(){
      if(this.settings.start_sta == 1){
        delete this.settings.start_time
      }else{
        if(this.settings.start_time){
          this.settings.start_time = moment(this.settings.start_time).format()
        }else{
          this.$alert(this.$t('lang.newFormSettingSendTime'),this.$t('lang.Tips'))
          return
        }
      }
      if(this.settings.off_sta == 0){
        delete this.settings.off_time
      }else{
        if(this.settings.off_time){
          this.settings.off_time = moment(this.settings.off_time).format()
        }else{
          this.$alert(this.$t('lang.formStopTIme'),this.$t('lang.Tips'))
          return
        }
      }
      if(this.settings.workflow_switch){
        let html = this.$refs.taskTitle.innerHTML.replace(/<br>/g, '')
        if(!html){
          this.$alert(this.$t('lang.taskTitleAlert'))
          return
        }else{
          this.settings.task_info.html = html
          this.settings.task_info.title = this.formatTitle(html)
        }
        if(!this.settings.related_projects){
          this.$alert(this.$t('lang.taskProjectAlert'))
          return
        }
      }else{
        this.settings.task_info = {}
        this.settings.related_projects = ''
      }
      this.$emit('confirm', this.settings)
    },
    formatSettings(){
      this.settings = {...this.formSettings}
      if(this.settings.sub_count == 0){
        this.settings.sub_count = false
      }else{
        this.settings.submitNum = this.settings.sub_count
        this.settings.sub_count = true
      }
      if(this.settings.start_sta == 1){
        this.settings.start_time = ''
      }else{
        this.settings.start_time = moment(this.settings.start_time).format('YYYY-MM-DD HH:mm:ss')
      }
      if(this.settings.off_sta == 0){
        this.settings.off_time = ''
      }else{
        this.settings.off_time = moment(this.settings.off_time).format('YYYY-MM-DD HH:mm:ss')
      }
      if(this.settings.workflow_switch){
        this.$nextTick(()=> {
          this.$refs.taskTitle.innerHTML = this.settings.task_info.html
        })
      }
      // console.log(this.settings)
    },
    formatTitle(title){
      return title.replace(/<span[\s\S]*?qid="/g, '@').replace(/" contenteditable="false">[\s\S]*?<\/span>/g, '@').replace(/&nbsp;/g, ' ')
    },
    questionTypeFilter(type){
      return {
        'text': this.$t('lang.newFormSubjecText'),
        'textarea': this.$t('lang.newFormSubjecText'),
        'radio': this.$t('lang.newFormSubjecInput'),
        'select': this.$t('lang.newFormSubjecInput'),
        'score': this.$t('lang.newFormScore'),
      }[type]
    },
    queryFormQuestions(){
      api.queryFormFilds({
        form_main_dbid: this.formSettings.id
      }).then(res => {
        if(res.data.filds_details.length == 0){
          this.$message.error(this.$t('lang.saveQuestionTip'))
        }else{
          this.questionList = res.data.filds_details
          this.questionList = this.questionList.filter(item => {
            return !['input', 'inputs', 'images'].includes(item.type)
          }).map(item => {
            return {
              id: item.id,
              value: item.title,
              text: `${this.$t('lang.question')}${item.order}: ${item.title}(${this.questionTypeFilter(item.type)})`
            }
          })
          this.tributeOptions.values = this.questionList
        }
      })
    },
    queryProjects(){
      api.queryProjects().then(res => {
        if(res.data && res.data.length > 0 ){
          this.projectsList = res.data
        }else{
          this.$message.error(this.$t('lang.noTaskProject'))
          this.settings.workflow_switch = false
        }
      })
    },
    tributeKeydown(e){
      if(e.key == "@"){
        e.preventDefault()
      }
    },
    tributeKeyup(e){
      if(this.$refs.taskTitle.innerHTML == '<br>'){
        this.$refs.taskTitle.innerHTML = ''
      }
    },
    closeTip(){
      this.form2taskTipShow = false
      localStorage.setItem('form2taskTip', 1)
    }
  }
}
</script>

<style lang="stylus">
  .setting-content{
    .item-label{
      font-size: 13px;
      color: #3d4757;
      margin-right: 20px;
      position: relative;
      white-space: nowrap;
      .tip{
        position: relative;
        background: #303133;
        color: #fff;
        border-radius: 4px;
        padding: 10px;
        z-index: 2000;
        font-size: 12px;
        line-height: 1.2;
        min-width: 10px;
        word-wrap: break-word;
        display: inline-block;
        margin-left: 10px;
        cursor: pointer;
        &::before{
          content: '';
          position: absolute;
          left: -12px;
          width: 0;
          height: @width;
          border: 6px solid transparent;
          border-right-color: #303133;
        }
      }
    }
    .select-label{
      display: inline-block;
      margin-right: 10px !important;
    }
    p{
      margin: 0;
      font-size: 13px;
      color: #767c85;
      i{
        font-size: 15px;
        margin-left: 5px;
      }
    }
    .submitNumInput .el-input{
      width: 80px;
      margin-right: 5px;
    }
    .el-switch{
      margin-right: 10px;
    }
  }  
  .tribute-container {
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    max-height: 300px;
    max-width: 500px;
    overflow: auto;
    display: block;
    z-index: 999999;
    border-radius: 4px;
    box-shadow: 0 1px 4px rgba(#000, 0.13);
  }
  .tribute-container ul {
    margin: 0;
    margin-top: 2px;
    padding: 0;
    list-style: none;
    background: #fff;
    border-radius: 4px;
    border: 1px solid rgba(#000, 0.13);
    background-clip: padding-box;
    overflow: hidden;
  }
  .tribute-container li {
    color: #767c85;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 14px;
  }
  .tribute-container li.highlight,
  .tribute-container li:hover {
    color: #5a9bf8;
  }
  .tribute-container li span {
    font-weight: bold;
  }
  .tribute-container li.no-match {
    cursor: default;
  }
  .tribute-container .menu-highlighted {
    font-weight: bold;
  }
  .task-title-input{
    background-color: #FFF;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    box-sizing: border-box;
    color: #606266;
    display: inline-block;
    font-size: inherit;
    min-height: 40px;
    line-height: 26px;
    outline: 0;
    padding: 6px 15px;
    transition: border-color .2s cubic-bezier(.645,.045,.355,1);
    width: 100%;
    &:hover{
      border-color: #C0C4CC;
    }
    &:focus{
      border-color: #409EFF;
      outline: 0;
    }
  }
  [contenteditable="true"]:empty:before {
    content: attr(placeholder);
    display: block;
    color: #C0C4CC;
  }
  .title-temp{
    color: #5a9bf8;
  }
</style>