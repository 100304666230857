import api from "../api/api"
import { Loading } from 'element-ui'

export default {
  config(){
    const devAppId = 'yd251CA7516E1540DDBF0053E94E08C09B',  // 测试环境appid
          masterAppId = 'ydCE08F73A59DD4102B21ABC939A44A800' // 正式环境appid
    let appId = location.origin == 'https://form.felo.me' ? masterAppId : devAppId
    FeloSDK.config({ appId })
  },
  async login(next, anonymously){
    this.config()
    let loading = Loading.service({
      lock: true,
      spinner: 'el-icon-loading',
      background: 'rgba(255, 255, 255, 0.4)'
    })
    await FeloSDK.login({
      anonymously,
    }).then( async data => {
      // console.log('logindata', data)
      if (data && data.length > 0){
        loading.close()
        this.formatLoginInfo(data)
        if(next){ next() }
      } else if (anonymously){
        await FeloSDK.loginAnonymous().then( data => {
          loading.close()
          // console.log('loginAnonymous', data)
          sessionStorage.setItem('anonymously', '1')
          localStorage.setItem("form_actoken", data.access_token)
          if(next){ next() }
        })
      }
    })
  },
  // async refresh(anonymously){
  //   this.config()
  //   console.log(anonymously)
  //   let loading = Loading.service({
  //     lock: true,
  //     spinner: 'el-icon-loading',
  //     background: 'rgba(255, 255, 255, 0.4)'
  //   })
  //   await FeloSDK.login({
  //     anonymously
  //   }).then( data => {
  //     loading.close()
  //     console.log('logindata', data)
  //     if (data && data.length > 0){
  //       this.formatLoginInfo(data, true)
  //       console.log(location)
  //       location.reload()
  //     }
  //   })
  // },
  formatLoginInfo(data){
    let info = {
      teams: [],
      tokens: {}
    }
    data.forEach(item => {
      info.tokens[item.team.team_id] = item.login_info.access_token
      info.teams.push({
        name: item.team.team_name,
        id: item.team.team_id
      })
    })
    // console.log(info)
    sessionStorage.setItem('anonymously', 0) 
    localStorage.setItem('login_info', JSON.stringify(info)) 
  },
  setTeam(){
    if(sessionStorage.getItem('anonymously') == 1){ return }
    let loginInfo = JSON.parse(localStorage.getItem('login_info')),
        selectedTeam = localStorage.getItem('team'),
        routerTeamId = sessionStorage.getItem('team_id'),
        teamIds = Object.keys(loginInfo.tokens),
        teamId = ''

    if(routerTeamId && teamIds.includes(routerTeamId)){
      teamId = routerTeamId
    }else if(selectedTeam && teamIds.includes(selectedTeam)){
      teamId = selectedTeam
    }else{
      teamId = loginInfo.teams[0].id
    }
    localStorage.setItem('team', teamId)
    localStorage.setItem("form_actoken", loginInfo.tokens[teamId])
  },
  judgeLogin(){
    return localStorage.getItem('form_actoken')
  },
  isMobile(){
    let browser={
      versions:function(){ 
        let u = navigator.userAgent
        return {//移动终端浏览器版本信息 
          trident: u.indexOf('Trident') > -1, //IE内核
          presto: u.indexOf('Presto') > -1, //opera内核
          webKit: u.indexOf('AppleWebKit') > -1, //苹果、谷歌内核
          gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, //火狐内核
          mobile: !!u.match(/AppleWebKit.*Mobile.*/), //是否为移动终端
          ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), //ios终端
          android: u.indexOf('Android') > -1 || u.indexOf('Linux') > -1, //android终端或者uc浏览器
          iPhone: u.indexOf('iPhone') > -1 , //是否为iPhone或者QQHD浏览器
          iPad: u.indexOf('iPad') > -1, //是否iPad  
          webApp: u.indexOf('Safari') == -1, //是否web应该程序，没有头部与底部
          weixin: u.indexOf('MicroMessenger') > -1, //是否微信 
          qq: u.match(/\sQQ/i) == " qq" //是否QQ
        };
      }(),
      language:(navigator.browserLanguage || navigator.language).toLowerCase()
    }   
    if(browser.versions.mobile || browser.versions.ios || browser.versions.android || 
      browser.versions.iPhone){  	//h5
      return true
    }else{
      return false
    }
  }
}